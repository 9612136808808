import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  addDoc,
  getFirestore,
  getDoc
} from "firebase/firestore";
import { ThemeProvider, StyledEngineProvider, createTheme, useTheme } from "@mui/material/styles";
import { db } from "./firebase";
import { reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth } from "./firebase";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const CadastrodeClientes = () => {
  const theme = useTheme();
  // Estados relacionados ao cliente e sua autenticação
  const [cliente, setCliente] = useState({
    id: "",
    nome: "",
    contato: "",
    credito: "",
    cpfCnpj: "",
    email: ""
  });
  const [senha, setSenha] = useState("");
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [clientesEncontrados, setClientesEncontrados] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  // Estados para UI
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleChange = event => {
    const { name, value } = event.target;

    // Transformando o texto em caixa alta
    const updatedValue =
      name === "nome" || name === "contato" || name === "chavePix"
        ? value.toUpperCase()
        : value;

    setCliente({ ...cliente, [name]: updatedValue });
  };

  const validateData = () => {
    // Adicione aqui validações mais específicas se necessário
    if (!cliente.nome) {
      setError("Por favor, preencha todos os campos obrigatórios.");
      return false;
    }
    return true;
  };
  // Função debounce básica para otimizar a busca enquanto digita
  function debounce(fn, delay) {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }

  useEffect(() => {
    // Verifica se o nome do cliente tem ao menos 1 caractere para iniciar a busca
    if (cliente.nome.trim().length > 0) {
      const busca = async () => {
        setLoading(true);
        try {
          // Define o termo de busca para ser o nome atual mais um caractere alto para a comparação '<'
          const startAtNome = cliente.nome.toUpperCase();
          const endAtNome = startAtNome + "\uf8ff";

          const q = query(
            collection(db, "EMPRESAS", empresaId, "Clientes"),
            where("nome", ">=", startAtNome),
            where("nome", "<=", endAtNome)
          );
          const querySnapshot = await getDocs(q);
          const resultados = [];
          querySnapshot.forEach(doc => {
            resultados.push({ id: doc.id, ...doc.data() });
          });
          setClientesEncontrados(resultados);
        } catch (error) {
          setError("Erro ao buscar clientes: " + error.message);
        } finally {
          setLoading(false);
        }
      };

      // Aplica debouncing na função de busca
      debounce(busca, 500)();
    } else {
      setClientesEncontrados([]); // Limpa os resultados se o input estiver vazio
    }
  }, [cliente.nome, db, empresaId]);

  const selecionarParaEdicao = cliente => {
    setCliente(cliente); // Assume que `setCliente` atualiza o estado do formulário com os dados do cliente
    setIsEditing(true); // Ativa o modo de edição
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!validateData()) {
      setError("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    setLoading(true);

    try {
      let clienteRef;
      if (isEditing && cliente.id) {
        // Usando o id existente para referenciar o documento quando estiver editando
        clienteRef = doc(db, "EMPRESAS", empresaId, "Clientes", cliente.id);
      } else {
        // Gerando um novo id com base no nome para novos cadastros
        const newId = cliente.nome
          .trim()
          .replace(/\s+/g, "-")
          .toLowerCase();
        clienteRef = doc(db, "EMPRESAS", empresaId, "Clientes", newId);
        // Atualizando o id do cliente com o novoId apenas para novos cadastros
        cliente.id = newId;
      }

      // Atualiza ou cria o documento com o id correto
      await setDoc(clienteRef, cliente, { merge: true });

      // Configura a mensagem de sucesso baseada na operação realizada
      const successMessage = isEditing
        ? "Cliente atualizado com sucesso!"
        : "Cliente cadastrado com sucesso!";
      setError(""); // Limpa erros anteriores
      setOpenSnackbar(true); // Abre o Snackbar
      setSnackbarMessage(successMessage); // Define a mensagem do Snackbar
      limparFormulario(); // Limpa o formulário
      setIsEditing(false); // Reseta o estado de edição
    } catch (error) {
      setError(error.message); // Mostra o erro
    } finally {
      setLoading(false); // Encerra o indicador de carregamento
    }
  };

  // Função para limpar o formulário
  const limparFormulario = () => {
    setCliente({
      // Supondo que existe uma função 'setCliente' para atualizar o estado do cliente
      id: "",
      nome: "",
      contato: "",
      cpfCnpj: "",
      email: "",
      credito: ""
    });
  };

  const handleChangeCPF = event => {
    const { value } = event.target;
    setCliente({ ...cliente, cpfCnpj: mascaraCPF(value) });
  };
  const mascaraCPF = valor => {
    return valor
      .replace(/\D/g, "") // Remove tudo o que não é dígito
      .replace(/(\d{3})(\d)/, "$1.$2") // Coloca ponto após os três primeiros dígitos
      .replace(/(\d{3})(\d)/, "$1.$2") // Repete para o próximo grupo de três dígitos
      .replace(/(\d{3})(\d{1,2})/, "$1-$2") // Coloca hífen antes dos últimos dois dígitos
      .replace(/(-\d{2})\d+?$/, "$1"); // Impede que digite mais do que 11 dígitos
  };
  const mascaraTelefone = valor => {
    let apenasNumeros = valor.replace(/\D/g, "");
    if (apenasNumeros.length > 11) {
      apenasNumeros = apenasNumeros.substr(0, 11); // Limitar a 11 dígitos
    }

    return ("+55 " + apenasNumeros
      .replace(/(\d{2})(\d)/, "($1) $2") // DDD entre parênteses
      .replace(/(\d{5})(\d)/, "$1-$2")); // Separar os 5 primeiros números do resto
  };

  const handleChangeTelefone = event => {
    const { value } = event.target;
    const valorFormatado = mascaraTelefone(value.replace("+55 ", "")); // Remove o +55 antes de aplicar a máscara
    setCliente({ ...cliente, contato: valorFormatado });
  };
  //Estado para gerar interação ao realizar o cadastro para o banco de dados
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage(false);
  };
  // Função para tratar mudanças no campo de crédito
  const handleChangeCredito = e => {
    const valorNumerico = e.target.value.replace(/[^\d,]/g, "");
    setCredito(valorNumerico);
  };
  const handleBlurCredito = () => {
    const valorFormatado = formatarComoMoeda(credito);
    setCredito(valorFormatado);
  };
  // Função para formatar valor como moeda
  const formatarComoMoeda = valor => {
    const numero = Number(valor);
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(numero);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleConfirmarSenha = async () => {
    const isAuthenticated = await reautenticar(senha);
    if (isAuthenticated) {
      setIsCreditoHabilitado(true);
    } else {
      setError("Senha incorreta.");
    }
    handleCloseModal(); // Fecha o modal independentemente do resultado
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        padding: isMdUp ? '20px 250px 20px 250px' : '20px',
      }}
    >
      <Paper elevation={6} sx={{ flex: 1, p: 4 }}>
        <Typography component="h1" variant="h5" align="center">
          Cadastro de Clientes
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {error && <Alert severity="error">{error}</Alert>}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="nome"
                label="Nome do Cliente"
                name="nome"
                autoComplete="nome"
                autoFocus
                value={cliente.nome}
                onChange={handleChange}
              />
            </Grid>
            {/* Repita o padrão Grid item para os outros campos */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                id="contato"
                label="Contato (Telefone)"
                name="contato"
                autoComplete="contato"
                value={cliente.contato}
                onChange={handleChangeTelefone}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                id="cpfCnpj"
                label="CPF/CNPJ"
                name="cpfCnpj"
                autoComplete="cpf-cnpj"
                value={cliente.cpfCnpj}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="E-mail do Cliente"
                name="email"
                autoComplete="email"
                value={cliente.email}
                onChange={handleChange}
              />
            </Grid>
            {clientesEncontrados.length > 0 && (
              <Paper
                elevation={4}
                sx={{
                  maxHeight: 300,
                  overflow: 'auto',
                  mt: 2,
                  mb: 2,
                  position: 'relative',
                  zIndex: 1,
                  minWidth: '300px',
                  maxWidth: '100%',
                  width: 'auto',
                  "&::-webkit-scrollbar": {
                    width: "10px"
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.background.paper
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.grey[400],
                    borderRadius: "2px",
                    "&:hover": {
                      backgroundColor: theme.palette.grey[500]
                    }
                  }
                }}
              >
                <List dense>
                  {clientesEncontrados.slice(0, 10).map((c, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={() => selecionarParaEdicao(c)}
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        padding: "10px 16px"
                      }}
                    >
                      <ListItemText
                        primary={c.nome}
                        secondary={`CPF/CNPJ: ${c.cpfCnpj || "Não informado"}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}

            <Dialog open={openModal} onClose={handleCloseModal}>
              <DialogTitle>Confirmar Senha</DialogTitle>
              <DialogContent>
                <form>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="senha"
                    label="Senha"
                    type="password"
                    fullWidth
                    variant="standard"
                    value={senha}
                    onChange={e => setSenha(e.target.value)}
                    autoComplete="current-password"
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Cancelar</Button>
                <Button onClick={handleConfirmarSenha}>Confirmar</Button>
              </DialogActions>
            </Dialog>
            {/* Botão de submissão */}
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant={isEditing ? "outlined" : "contained"}
                color={isEditing ? "primary" : "secondary"}
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : isEditing ? (
                  "Editar"
                ) : (
                  "Cadastrar"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
}
export default CadastrodeClientes;
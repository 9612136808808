import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  CardMedia,
  CircularProgress,
  IconButton,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Snackbar,
  Box,
  Divider,
  Autocomplete
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { ref, uploadBytes, getStorage } from "firebase/storage";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  setDoc,
  doc
} from "firebase/firestore";
import { getDownloadURL } from "firebase/storage"; // Importação adicional
import { db } from "./firebase";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import MuiAlert from "@mui/material/Alert";

const Cadastrodepecas = () => {
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [descricao, setDescricao] = useState("");
  const [tamanho, setTamanho] = useState("");
  const [marca, setMarca] = useState("");
  const [categoria, setCategoria] = useState("");
  const [fornecedor, setFornecedor] = useState("");
  const [dataEntrada, setDataEntrada] = useState("");
  const [fornecedores, setFornecedores] = useState([]);
  const [valorVenda, setValorVenda] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [isFornecedorCora, setIsFornecedorCora] = useState(false);
  const [valorCompra, setValorCompra] = useState("");
  const [codigoPeca, setCodigoPeca] = useState("");
  const [image, setImage] = useState(null); // Estado para o objeto File da imagem
  const [imagePreview, setImagePreview] = useState("/static/imagem_padrao.png"); // Estado para a URL da imagem para pré-visualização
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]); // Novo estado para categorias
  const [marcas, setMarcas] = useState([]); // Novo estado para marcas
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  // Carregar fornecedores, categorias e marcas do Firebase
  useEffect(() => {
    const fetchFornecedores = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores")
        );
        const fornecedoresData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          nome: doc.data().nome
        }));
        setFornecedores(fornecedoresData);
      } catch (error) {
        console.error("Erro ao buscar fornecedores:", error);
      }
    };

    const fetchCategorias = async () => {
      try {
        const empresaDocRef = doc(db, "EMPRESAS", empresaId);
        const empresaDoc = await getDoc(empresaDocRef);
        if (empresaDoc.exists()) {
          setCategorias(empresaDoc.data().Categorias || []);
          setMarcas(empresaDoc.data().Marcas || []);
        } else {
          console.error("Documento da empresa não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    };

    if (empresaId) {
      fetchFornecedores();
      fetchCategorias();
    }
  }, [empresaId]);

  const verificarCriarSubcolecoes = async empresaId => {
    try {
      // Criar um documento 'dados' dentro de 'Cadastro de Peças'
      const dadosRef = doc(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de Peças",
        "dados"
      );
      await setDoc(dadosRef, { inicializado: true }, { merge: true });

      // Criar documentos iniciais nas subcoleções 'Estoque Ativo' e 'Vendidos'
      const estoqueAtivoRef = collection(dadosRef, "Estoque Ativo");
      const vendidosRef = collection(dadosRef, "Vendidos");

      await Promise.all([
        setDoc(
          doc(estoqueAtivoRef, "inicial"),
          { inicializado: true },
          { merge: true }
        ),
        setDoc(
          doc(vendidosRef, "inicial"),
          { inicializado: true },
          { merge: true }
        )
      ]);
    } catch (error) {
      console.error("Erro ao verificar/criar subcoleções:", error);
    }
  };

  // Função para atualizar o total de itens no estoque
  const atualizarTotalEstoque = async quantidadeAdicionada => {
    const quantidade = Number(quantidadeAdicionada); // Certifique-se de que é um número
    const estoqueValorRef = doc(
      db,
      "EMPRESAS",
      empresaId,
      "Estoque valor",
      "numero"
    );

    try {
      const docSnap = await getDoc(estoqueValorRef);
      let novoTotal = quantidade;

      if (docSnap.exists()) {
        const totalAtual = docSnap.data().Total || 0;
        novoTotal += totalAtual;
      }

      await setDoc(estoqueValorRef, { Total: novoTotal }, { merge: true });
      console.log("Total de estoque atualizado com sucesso: ", novoTotal);
    } catch (error) {
      console.error("Erro ao atualizar o total de estoque:", error);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (
      !descricao ||
      !tamanho ||
      !marca ||
      !categoria ||
      !valorVenda ||
      !quantidade
    ) {
      setSnackbarMessage("Por favor, preencha todos os campos obrigatórios.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      // Verificar e criar as subcoleções necessárias
      await verificarCriarSubcolecoes(empresaId);

      // Upload da imagem
      let imageUrl = await uploadImage();

      // Se o checkbox 'Fornecedor Cora' estiver marcado, alterar o valor do fornecedor
      const fornecedorFinal = isFornecedorCora ? null : fornecedor;

      // Gerar um código único para a peça
      const codigo = gerarCodigoPeca();
      setCodigoPeca(codigo);

      // Preparar dados da nova peça
      const novaPecaData = {
        descricao,
        tamanho,
        marca,
        categoria,
        fornecedor: fornecedorFinal,
        dataEntrada,
        valorVenda: parseFloat(valorVenda.replace(/\./g, "").replace(",", ".")), // Salva como número
        quantidade: parseInt(quantidade, 10),
        valorCompra: isFornecedorCora
          ? parseFloat(valorCompra.replace(/\./g, "").replace(",", "."))
          : null, // Salva como número se for fornecedor próprio
        codigoPeca: codigo,
        imageUrl
      };

      // Salvar a nova peça na subcoleção 'Estoque Ativo'
      const novaPecaRef = doc(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de Peças",
        "dados",
        "Estoque Ativo",
        codigo
      );
      await setDoc(novaPecaRef, novaPecaData);

      // Atualizar o total de itens no estoque
      await atualizarTotalEstoque(quantidade);

      // Imprimir etiqueta após o cadastro bem-sucedido
      imprimirEtiqueta(codigo);

      setSnackbarMessage("Peça cadastrada e etiqueta impressa com sucesso.");
      setSnackbarSeverity("success");

      // Limpar formulário
      resetFormFields();
    } catch (error) {
      console.error("Erro ao cadastrar peça:", error);
      setSnackbarMessage(`Erro ao cadastrar peça: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const uploadImage = async () => {
    if (image && image instanceof File) {
      try {
        const storage = getStorage();
        const imageName = `${descricao}-${Date.now()}`;
        const imageRef = ref(storage, `${empresaId}/imagens/${imageName}`);

        const uploadResult = await uploadBytes(imageRef, image);
        return await getDownloadURL(uploadResult.ref);
      } catch (error) {
        console.error("Erro ao fazer upload da imagem:", error);
        return "/static/imagem_padrao.png"; // Retorna um valor padrão ou lida com o erro de forma adequada
      }
    }
    return "/static/imagem_padrao.png";
  };

  const resetFormFields = () => {
    setDescricao("");
    setTamanho("");
    setMarca("");
    setCategoria("");
    setFornecedor("");
    setDataEntrada("");
    setValorVenda("");
    setQuantidade("");
    setIsFornecedorCora(false);
    setValorCompra("");
    setImage(null);
    setImagePreview("/static/imagem_padrao.png");
  };

  const salvarImagemNoStorage = async (imagem, empresaId, descricao) => {
    const storage = getStorage();
    const imageName = descricao.replace(/\s+/g, "-").toLowerCase();
    const storageRef = ref(storage, `${empresaId}/imagens/${imageName}`);

    if (imagem instanceof File || imagem instanceof Blob) {
      await uploadBytes(storageRef, imagem);
      // Adicional: obter URL da imagem após o upload, se necessário
    }
  };

  const handleImageUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImage(file);
      setImagePreview(previewUrl);

      // Sugestão: libere a URL anterior se necessário
      return () => {
        URL.revokeObjectURL(previewUrl);
      };
    }
  };


  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const gerarCodigoPeca = () => {
    const data = new Date();
    const codigo = data
      .getTime()
      .toString()
      .slice(-8); // Últimos 4 dígitos do timestamp
    return codigo;
  };

  const imprimirEtiqueta = codigo => {
    let canvas = document.createElement("canvas");
    JsBarcode(canvas, codigo, { format: "CODE39", width: 2, height: 40 });
    let codigoBarras = canvas.toDataURL("image/png");

    QRCode.toDataURL(codigo)
      .then(url => {
        let qrCode = url;

        let conteudoEtiqueta = `
          <div>
            <p>Código da Peça: ${codigo}</p>
            <p>Descrição: ${descricao}</p>
            <p>Tamanho: ${tamanho}</p>
            <p>Marca: ${marca}</p>
            <p>Categoria: ${categoria}</p>
            <p>Valor de Venda: ${valorVenda}</p>
            <img src="${codigoBarras}" alt="Código de Barras"/>
            <img src="${qrCode}" alt="QR Code"/>
          </div>
        `;

        const janelaImpressao = window.open("", "_blank");
        janelaImpressao.document.write(conteudoEtiqueta);
        janelaImpressao.document.close();

        // Certifique-se de que a janela está totalmente carregada antes de manipular
        janelaImpressao.onload = () => {
          janelaImpressao.focus();
          janelaImpressao.print();
          janelaImpressao.close();
        };
      })
      .catch(error => {
        console.error("Erro ao gerar QR Code:", error);
      });
  };



  const handleCurrencyChange = (event, setFunction) => {
    // Lógica para formatação de moeda
    let valor = event.target.value.replace(/\D/g, ""); // Remove tudo que não é dígito
    valor = (valor / 100).toFixed(2) + ""; // Converte para formato monetário
    valor = valor.replace(".", ",");
    valor = valor.replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    setFunction(valor);
  };

  const handleFornecedorCoraChange = event => {
    setIsFornecedorCora(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        padding: isMdUp ? '20px 250px' : '10px',
      }}
    >
      <Card
        sx={{
          flex: 1,
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          padding: { xs: '10px', sm: '15px', md: '20px' },
          borderRadius: '12px',
         
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              marginBottom: { xs: '10px', sm: '15px', md: '20px' },
              fontWeight: 'bold',
              fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.5rem' },
            }}
          >
            Cadastro de Peças
          </Typography>
          <Divider sx={{ marginBottom: { xs: '10px', sm: '15px', md: '20px' } }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CardMedia
                component="img"
                sx={{
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  marginBottom: '10px',
                  height: { xs: '150px', sm: '180px', md: '200px' },
                }}
                image={imagePreview} // Usar imagePreview para a visualização da imagem
                alt="Imagem da Peça"
              />
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="upload-image"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="upload-image">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  sx={{ width: '100%', fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                  startIcon={<PhotoCamera />}
                >
                  Upload
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} md={8}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Descrição do Produto"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value.toUpperCase())}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Tamanho"
                      value={tamanho}
                      onChange={(e) => setTamanho(e.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      freeSolo
                      options={marcas}
                      inputValue={marca}
                      onInputChange={(event, newInputValue) => {
                        setMarca(newInputValue.toUpperCase());
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marca"
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          sx={{
                            fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    >
                      <InputLabel>Categoria</InputLabel>
                      <Select
                        value={categoria}
                        label="Categoria"
                        onChange={(e) => setCategoria(e.target.value)}
                      >
                        {categorias.map((cat, index) => (
                          <MenuItem key={index} value={cat}>
                            {cat}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Data de Entrada"
                      type="date"
                      value={dataEntrada}
                      onChange={(e) => setDataEntrada(e.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Valor da Peça (Venda)"
                      type="text"
                      value={valorVenda}
                      onChange={(e) => handleCurrencyChange(e, setValorVenda)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Quantidade"
                      type="number"
                      value={quantidade}
                      onChange={(e) => setQuantidade(e.target.value)}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isFornecedorCora}
                          onChange={(e) => {
                            setIsFornecedorCora(e.target.checked);
                            if (e.target.checked) {
                              setFornecedor(''); // Se 'Fornecedor Próprio' estiver marcado, limpe a seleção de fornecedores
                            }
                          }}
                          disabled={fornecedor} // Desabilita o checkbox se um fornecedor estiver selecionado
                        />
                      }
                      label="Fornecedor Próprio"
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      disabled={isFornecedorCora}
                      sx={{
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                    >
                      <InputLabel>Fornecedor</InputLabel>
                      <Select
                        value={fornecedor}
                        label="Fornecedor"
                        onChange={(e) => {
                          setFornecedor(e.target.value);
                          if (e.target.value) {
                            setIsFornecedorCora(false);
                          }
                        }}
                      >
                        <MenuItem value="">Nenhum</MenuItem>
                        {fornecedores.map((forn) => (
                          <MenuItem key={forn.id} value={forn.id}>
                            {forn.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {isFornecedorCora && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Valor de Compra"
                        type="text"
                        value={valorCompra}
                        onChange={(e) => handleCurrencyChange(e, setValorCompra)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                        sx={{
                          fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        marginTop: '20px',
                        padding: { xs: '8px', sm: '10px' },
                        fontWeight: 'bold',
                        borderRadius: '8px',
                        textTransform: 'none',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                        fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        'Cadastrar Peça'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </CardContent>
      </Card>
    </Box>
  );
};
export default Cadastrodepecas;

import { createContext, useState, useEffect } from "react";
import { auth } from "./firebase"; // Importe o auth do seu arquivo de configuração do Firebase
import { getFirestore, doc, getDoc } from "firebase/firestore"; // Importações adicionais do Firestore

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userDetails, setUserDetails] = useState({ empresaId: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setCurrentUser(user);

      if (user) {
        // Buscar empresaId do Firestore
        try {
          const userDocRef = doc(getFirestore(), "USUARIOS", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const { empresaId } = userDoc.data();
            setUserDetails({ empresaId });
          }
        } catch (error) {
          console.error("Erro ao buscar dados do usuário:", error);
        }
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Monitor de inatividade
  useEffect(() => {
    const TIMEOUT = 30 * 60 * 1000; // 30 minutos, por exemplo
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      if (currentUser) {
        logoutTimer = setTimeout(() => auth.signOut(), TIMEOUT);
      }
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);

    resetTimer();

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, [currentUser]);

  const value = {
    currentUser,
    userDetails,
    signOut: auth.signOut,
    loginUser: async (email, password) => {
      try {
        await auth.signInWithEmailAndPassword(email, password);
        // Mais lógica se necessário
      } catch (error) {
        console.error("Erro no login:", error);
        throw error; // Lançar para ser tratado no componente de login
      }
    },
    registerUser: async (email, password) => {
      try {
        await auth.createUserWithEmailAndPassword(email, password);
        // Mais lógica se necessário
      } catch (error) {
        console.error("Erro no cadastro:", error);
        throw error; // Lançar para ser tratado no componente de cadastro
      }
    }
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

import React, { useContext, useState, useEffect, useMemo } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import { NumberFormatBase } from "react-number-format";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  FormControl,
  IconButton,
  TextField,
  Modal,
  FormControlLabel,
  Box,
  Select,
  InputLabel,
  Switch,
  useMediaQuery,
  Typography,
  MenuItem,
  Button,
  Grid,
  Card,
  CardContent
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import StoreIcon from "@mui/icons-material/Store";
import { useReactToPrint } from "react-to-print";
import ImpressaoEtiqueta from "./ImpressaoEtiqueta";
import RelatorioEstoque from "./RelatorioEstoque";
import { parseISO, differenceInDays, isValid, isWithinInterval } from "date-fns";


const formatCurrency = value => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);
};

const carregarProdutos = async (
  userDetails,
  setProdutos,
  setOpcoesFornecedor,
  setOpcoesMarca,
  setOpcoesCategoria,
  setOpcoesTamanho,
  setQuantidadeTotal
) => {
  if (userDetails && userDetails.empresaId) {
    const querySnapshot = await getDocs(
      collection(
        db,
        "EMPRESAS",
        userDetails.empresaId,
        "Cadastro de Peças",
        "dados",
        "Estoque Ativo"
      )
    );
    const produtosData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setProdutos(produtosData);

    const fornecedores = [
      ...new Set(produtosData.map(produto => produto.fornecedor))
    ];
    const marcas = [...new Set(produtosData.map(produto => produto.marca))];
    const tamanhos = [...new Set(produtosData.map(produto => produto.tamanho))];
    const categoria = [
      ...new Set(produtosData.map(produto => produto.categoria))
    ];

    setOpcoesFornecedor(fornecedores);
    setOpcoesMarca(marcas);
    setOpcoesCategoria(categoria);
    setOpcoesTamanho(tamanhos);

    const totalQuantidade = produtosData.reduce(
      (total, produto) => total + (Number(produto.quantidade) || 0),
      0
    );
    setQuantidadeTotal(totalQuantidade);
  }
};

const GerenciarProdutos = () => {
  const { userDetails } = useContext(AuthContext);
  const [produtos, setProdutos] = useState([]);
  const [busca, setBusca] = useState("");
  const [filtroFornecedor, setFiltroFornecedor] = useState("");
  const [filtroMarca, setFiltroMarca] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [filtroTamanho, setFiltroTamanho] = useState("");
  const [quantidadeTotal, setQuantidadeTotal] = useState(0);
  const [produtoEditavel, setProdutoEditavel] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);
  const [opcoesFornecedor, setOpcoesFornecedor] = useState([]);
  const [opcoesMarca, setOpcoesMarca] = useState([]);
  const [opcoesCategoria, setOpcoesCategoria] = useState([]);
  const [opcoesTamanho, setOpcoesTamanho] = useState([]);
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);
  const [filtroAcima30Dias, setFiltroAcima30Dias] = useState(false);
  const [filtroAcima60Dias, setFiltroAcima60Dias] = useState(false);
  const [modalExclusaoAberto, setModalExclusaoAberto] = useState(false);
  const [produtoParaExcluir, setProdutoParaExcluir] = useState(null);
  const [buscandoProdutos, setBuscandoProdutos] = useState(false);
  const [produtoParaImpressao, setProdutoParaImpressao] = useState(null);
  const [totalValue, setTotalValue] = useState(0);
  const relatorioRef = React.useRef();
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const isMdUp = useMediaQuery("(min-width:960px)");

  useEffect(() => {
    carregarProdutos(
      userDetails,
      setProdutos,
      setOpcoesFornecedor,
      setOpcoesMarca,
      setOpcoesCategoria,
      setOpcoesTamanho,
      setQuantidadeTotal
    );
  }, [userDetails]);

  useEffect(() => {
    fetchTotalValue();
  }, []);

  const fetchTotalValue = async () => {
    const docRef = doc(
      db,
      "EMPRESAS",
      userDetails.empresaId,
      "Estoque valor",
      "numero"
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setTotalValue(docSnap.data().Total);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    const filtrarProdutos = () => {
      let filtrados = produtos;

      filtrados = filtrados.filter(produto => {
        const descricao = produto.descricao
          ? produto.descricao.toLowerCase()
          : "";
        const codigoPeca = produto.codigoPeca
          ? produto.codigoPeca.toLowerCase()
          : "";
        return (
          (descricao.includes(busca.toLowerCase()) ||
            codigoPeca.includes(busca.toLowerCase())) &&
          (filtroFornecedor ? produto.fornecedor === filtroFornecedor : true) &&
          (filtroMarca ? produto.marca === filtroMarca : true) &&
          (filtroCategoria ? produto.categoria === filtroCategoria : true) &&
          (filtroTamanho ? produto.tamanho === filtroTamanho : true)
        );
      });

      if (filtroAcima30Dias) {
        filtrados = filtrados.filter(produto => {
          const diasNaLoja = differenceInDays(
            new Date(),
            parseISO(produto.dataEntrada)
          );
          return diasNaLoja > 30;
        });
      }

      if (filtroAcima60Dias) {
        filtrados = filtrados.filter(produto => {
          const diasNaLoja = differenceInDays(
            new Date(),
            parseISO(produto.dataEntrada)
          );
          return diasNaLoja > 60;
        });
      }

      if (dataInicial && dataFinal) {
        const dataInicioParsed = parseISO(dataInicial);
        const dataFimParsed = parseISO(dataFinal);

        if (isValid(dataInicioParsed) && isValid(dataFimParsed)) {
          const intervalo = { start: dataInicioParsed, end: dataFimParsed };

          filtrados = filtrados.filter(produto => {
            const dataEntradaParsed = parseISO(produto.dataEntrada);

            return (
              isValid(dataEntradaParsed) &&
              isWithinInterval(dataEntradaParsed, intervalo)
            );
          });
        } else {
          console.error("Intervalo de datas inválido");
        }
      }

      setProdutosFiltrados(filtrados);
    };

    filtrarProdutos();
  }, [
    busca,
    filtroFornecedor,
    filtroMarca,
    filtroCategoria,
    filtroTamanho,
    dataInicial,
    dataFinal,
    produtos,
    filtroAcima30Dias,
    filtroAcima60Dias
  ]);

  const handleEditar = produto => {
    produto.quantidadeOriginal = produto.quantidade;
    setProdutoEditavel(produto);
    setModalAberto(true);
  };

  const atualizarTotalEstoqueComDiferenca = async diferenca => {
    const estoqueValorRef = doc(
      db,
      "EMPRESAS",
      userDetails.empresaId,
      "Estoque valor",
      "numero"
    );

    try {
      const docSnap = await getDoc(estequeValorRef);

      if (docSnap.exists()) {
        const totalAtual = docSnap.data().Total || 0;
        const novoTotal = totalAtual + diferenca;

        await updateDoc(estoqueValorRef, { Total: novoTotal });
        console.log("Total de estoque atualizado com sucesso!");
      } else {
        console.log(
          "Documento 'numero' não encontrado. Verifique o caminho no Firestore."
        );
      }
    } catch (error) {
      console.error("Erro ao atualizar o total de estoque:", error);
    }
  };

  const handleSalvarEdicao = async () => {
    try {
      const diferencaQuantidade =
        produtoEditavel.quantidade - produtoEditavel.quantidadeOriginal;

      await updateDoc(
        doc(
          db,
          "EMPRESAS",
          userDetails.empresaId,
          "Cadastro de Peças",
          "dados",
          "Estoque Ativo",
          produtoEditavel.id
        ),
        {
          tamanho: produtoEditavel.tamanho,
          valorVenda: parseFloat(produtoEditavel.valorVenda.replace(",", ".")), // Convertendo para número
          marca: produtoEditavel.marca,
          descricao: produtoEditavel.descricao,
          dataEntrada: produtoEditavel.dataEntrada,
          quantidade: parseInt(produtoEditavel.quantidade, 10), // Convertendo para número
          codigoPeca: produtoEditavel.codigoPeca
        }
      );

      if (diferencaQuantidade !== 0) {
        await atualizarTotalEstoqueComDiferenca(diferencaQuantidade);
      }
      setModalAberto(false);
      setProdutoEditavel(null);
      carregarProdutos(
        userDetails,
        setProdutos,
        setOpcoesFornecedor,
        setOpcoesMarca,
        setOpcoesCategoria,
        setOpcoesTamanho,
        setQuantidadeTotal
      );
    } catch (error) {
      console.error("Erro ao salvar edição:", error);
    }
  };

  const handleCancelarEdicao = () => {
    setModalAberto(false);
    setProdutoEditavel(null);
  };

  const handleExcluir = async id => {
    handleAbrirModalExclusao();
    setProdutoParaExcluir({ id });
  };

  const handleConfirmarExclusao = async () => {
    try {
      if (produtoParaExcluir) {
        await deleteDoc(
          doc(
            db,
            "EMPRESAS",
            userDetails.empresaId,
            "Cadastro de Peças",
            "dados",
            "Estoque Ativo",
            produtoParaExcluir.id
          )
        );
      }
      setModalExclusaoAberto(false);
      setProdutoParaExcluir(null);
      carregarProdutos(
        userDetails,
        setProdutos,
        setOpcoesFornecedor,
        setOpcoesMarca,
        setOpcoesCategoria,
        setOpcoesTamanho,
        setQuantidadeTotal
      );
    } catch (error) {
      console.error("Erro ao excluir produto:", error);
    }
  };

  const handleAbrirModalExclusao = () => {
    setModalExclusaoAberto(true);
  };

  const handleCancelarExclusao = () => {
    setModalExclusaoAberto(false);
    setProdutoParaExcluir(null);
  };

  const handleImprimir = produto => {
    setProdutoParaImpressao(produto);
  };

  const handleImprimirRelatorio = () => {
    if (relatorioRef.current) {
      relatorioRef.current.handlePrint();
    }
  };

  const fornecedorStats = useMemo(() => {
    const stats = {
      quantidade: 0,
      valorTotal: 0
    };

    produtosFiltrados.forEach(produto => {
      if (produto.fornecedor === filtroFornecedor) {
        const quantidade = Number(produto.quantidade) || 0;
        const valorVenda =
          typeof produto.valorVenda === "string"
            ? parseFloat(produto.valorVenda.replace(",", "."))
            : Number(produto.valorVenda);
        stats.quantidade += quantidade;
        stats.valorTotal += quantidade * valorVenda;
      }
    });

    return stats;
  }, [produtosFiltrados, filtroFornecedor]);

  return (
    (<Container
                                                                                  maxWidth="xl"
                                                                                  sx={{ marginTop: "20px", paddingLeft: { xs: "0", md: "250px" } }}
                                                                                >
      <Box sx={{ backgroundColor: "#f5f5f5", minHeight: "100vh", py: 3 }}>
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: "#ffffff",
            py: 3,
            borderRadius: 1,
            boxShadow: 3
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: "bold",
              textAlign: "center",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)"
            }}
          >
            Gerenciar Produtos
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Buscar Produto"
                variant="outlined"
                fullWidth
                value={busca}
                onChange={e => setBusca(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Fornecedor</InputLabel>
                <Select
                  value={filtroFornecedor}
                  onChange={e => setFiltroFornecedor(e.target.value)}
                  label="Fornecedor"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {opcoesFornecedor.map(fornecedor => (
                    <MenuItem key={fornecedor} value={fornecedor}>
                      {fornecedor}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Marca</InputLabel>
                <Select
                  value={filtroMarca}
                  onChange={e => setFiltroMarca(e.target.value)}
                  label="Marca"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {opcoesMarca.map(marca => (
                    <MenuItem key={marca} value={marca}>
                      {marca}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Categoria</InputLabel>
                <Select
                  value={filtroCategoria}
                  onChange={e => setFiltroCategoria(e.target.value)}
                  label="Categoria"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {opcoesCategoria.map(categoria => (
                    <MenuItem key={categoria} value={categoria}>
                      {categoria}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Tamanho</InputLabel>
                <Select
                  value={filtroTamanho}
                  onChange={e => setFiltroTamanho(e.target.value)}
                  label="Tamanho"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {opcoesTamanho.map(tamanho => (
                    <MenuItem key={tamanho} value={tamanho}>
                      {tamanho}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Data Inicial"
                type="date"
                variant="outlined"
                fullWidth
                value={dataInicial}
                onChange={e => setDataInicial(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Data Final"
                type="date"
                variant="outlined"
                fullWidth
                value={dataFinal}
                onChange={e => setDataFinal(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      bgcolor: "green",
                      color: "white"
                    }}
                  >
                    <StoreIcon sx={{ mr: 2, fontSize: 40 }} />
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        Total do Estoque
                      </Typography>
                      <Typography variant="body1">
                        {totalValue} Peças
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
                {filtroFornecedor && (
                  <Grid item xs={12} md={6}>
                    <Card
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 2,
                        bgcolor: "blue",
                        color: "white"
                      }}
                    >
                      <StoreIcon sx={{ mr: 2, fontSize: 40 }} />
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          Estoque do Fornecedor
                        </Typography>
                        <Typography variant="body1">
                          Quantidade: {fornecedorStats.quantidade} Peças
                        </Typography>
                        <Typography variant="body1">
                          Valor Total:{" "}
                          {formatCurrency(fornecedorStats.valorTotal)}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={filtroAcima30Dias}
                    onChange={e => setFiltroAcima30Dias(e.target.checked)}
                  />
                }
                label="Produtos acima de 30 dias na loja"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={filtroAcima60Dias}
                    onChange={e => setFiltroAcima60Dias(e.target.checked)}
                  />
                }
                label="Produtos acima de 60 dias na loja"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<PrintIcon />}
                variant="contained"
                color="primary"
                onClick={handleImprimirRelatorio}
              >
                Imprimir Relatório
              </Button>
            </Grid>
            <div style={{ display: "none" }}>
              <RelatorioEstoque
                ref={relatorioRef}
                produtosFiltrados={produtosFiltrados}
              />
            </div>
          </Grid>
          <List sx={{ mt: 3 }}>
            {produtosFiltrados.map(produto => {
              const diasNaLoja = differenceInDays(
                new Date(),
                parseISO(produto.dataEntrada)
              );
              let bgcolor;

              if (diasNaLoja > 60) {
                bgcolor = "#ffccbc";
              } else if (diasNaLoja > 30 && diasNaLoja <= 60) {
                bgcolor = "#ffff8d";
              } else {
                bgcolor = "#fff";
              }

              return (
                (<ListItem
                  key={produto.id}
                  divider
                  sx={{ bgcolor: bgcolor, mb: 1, borderRadius: 1 }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={produto.descricao}
                      src={produto.imageUrl || "/static/imagem_padrao.png"}
                      sx={{ width: 48, height: 48 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        {produto.descricao}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" color="text.primary">
                          Tamanho: {produto.tamanho} | Categoria:{" "}
                          {produto.categoria}
                        </Typography>
                        <Typography variant="body2" color="text.primary">
                          Código: {produto.codigoPeca} | Data:{" "}
                          {produto.dataEntrada}
                        </Typography>
                      </>
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end"
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ mb: 0.5 }}
                    >
                      Fornecedor: {produto.fornecedor}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ mb: 0.5 }}
                    >
                      Marca: {produto.marca} | Quantidade: {produto.quantidade}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ mb: 0.5 }}
                    >
                      Valor: R${" "}
                      {parseFloat(
                        (produto.valorVenda || "0").toString().replace(",", ".")
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 8
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton onClick={() => handleEditar(produto)} size="large">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleExcluir(produto.id)} size="large">
                        <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={() => handleImprimir(produto)} size="large">
                        <PrintIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </ListItem>)
              );
            })}
          </List>
          <Modal open={modalExclusaoAberto} onClose={handleCancelarExclusao}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                p: 4,
                borderRadius: 1,
                boxShadow: 24
              }}
            >
              <Typography variant="h6">
                Confirmar exclusão do item "{produtoParaExcluir?.descricao}"?
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmarExclusao}
                >
                  Confirmar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelarExclusao}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={modalAberto} onClose={() => setModalAberto(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 5,
                p: 2,
                borderRadius: 1
              }}
            >
              <Typography variant="h6">Editar Produto</Typography>
              <TextField
                label="Descrição"
                variant="outlined"
                fullWidth
                value={produtoEditavel?.descricao || ""}
                onChange={e =>
                  setProdutoEditavel({
                    ...produtoEditavel,
                    descricao: e.target.value
                  })
                }
              />
              <TextField
                label="Marca"
                variant="outlined"
                fullWidth
                value={produtoEditavel?.marca || ""}
                onChange={e =>
                  setProdutoEditavel({
                    ...produtoEditavel,
                    marca: e.target.value
                  })
                }
              />
              <TextField
                label="Tamanho"
                variant="outlined"
                fullWidth
                value={produtoEditavel?.tamanho || ""}
                onChange={e =>
                  setProdutoEditavel({
                    ...produtoEditavel,
                    tamanho: e.target.value
                  })
                }
              />
              <TextField
                label="Quantidade"
                variant="outlined"
                fullWidth
                value={produtoEditavel?.quantidade || ""}
                onChange={e =>
                  setProdutoEditavel({
                    ...produtoEditavel,
                    quantidade: e.target.value.replace(/\D/g, "") // Permitindo apenas números
                  })
                }
              />
              <TextField
                label="Código da peça"
                variant="outlined"
                fullWidth
                value={produtoEditavel?.codigoPeca || ""}
                onChange={e =>
                  setProdutoEditavel({
                    ...produtoEditavel,
                    codigoPeca: e.target.value
                  })
                }
              />
              <TextField
                label="Preço"
                variant="outlined"
                fullWidth
                value={produtoEditavel?.valorVenda || ""}
                onChange={e =>
                  setProdutoEditavel({
                    ...produtoEditavel,
                    valorVenda: e.target.value.replace(/[^0-9,]/g, "") // Permitindo apenas números e vírgulas
                  })
                }
              />
              <TextField
                label="Data de Entrada"
                variant="outlined"
                fullWidth
                value={produtoEditavel?.dataEntrada || ""}
                onChange={e =>
                  setProdutoEditavel({
                    ...produtoEditavel,
                    dataEntrada: e.target.value
                  })
                }
              />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSalvarEdicao}
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelarEdicao}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          </Modal>
          {produtoParaImpressao && (
            <ImpressaoEtiqueta
              key={produtoParaImpressao.id}
              codigo={produtoParaImpressao.codigoPeca}
              descricao={produtoParaImpressao.descricao}
              tamanho={produtoParaImpressao.tamanho}
              marca={produtoParaImpressao.marca}
              categoria={produtoParaImpressao.categoria}
              valorVenda={produtoParaImpressao.valorVenda}
            />
          )}
        </Container>
      </Box>
    </Container>)
  );
};

export default GerenciarProdutos;

// DashboardPage.js
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs
} from "firebase/firestore";
import Sidebar from "./Sidebar";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell
} from "recharts";

const COLORS = ["#8884d8", "#82ca9d", "#ffc658", "#ff7300", "#ff4f81", "#845bef"];

const DashboardPage = () => {
  const { userDetails } = useContext(AuthContext);
  const [logoutError, setLogoutError] = useState("");
  const empresaId = userDetails.empresaId;
  const [empresaData, setEmpresaData] = useState({});
  const [vendasData, setVendasData] = useState([]);
  const [estoqueData, setEstoqueData] = useState([]);
  const [vendasPorCategoria, setVendasPorCategoria] = useState([]);
  const [vendasMensais, setVendasMensais] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const fetchEmpresaData = async () => {
      if (empresaId) {
        setIsLoading(true);
        try {
          const db = getFirestore();
          const empresaDocRef = doc(db, "EMPRESAS", empresaId);
          const empresaDoc = await getDoc(empresaDocRef);

          if (empresaDoc.exists()) {
            setEmpresaData(empresaDoc.data());
          } else {
            setError("Empresa não encontrada.");
          }
        } catch (error) {
          console.error("Erro ao buscar dados da empresa:", error);
          setError("Erro ao buscar dados da empresa.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    const fetchVendasData = async () => {
      if (empresaId) {
        const db = getFirestore();
        const vendasData = [];
        const currentYear = new Date().getFullYear();

        try {
          for (let month = 1; month <= 12; month++) {
            for (let day = 1; day <= 31; day++) {
              const vendasCollectionRef = collection(
                db,
                `EMPRESAS/${empresaId}/Vendas/${currentYear}/${month}/${day}/Vendas`
              );
              const vendasSnapshot = await getDocs(vendasCollectionRef);
              vendasSnapshot.forEach(doc => {
                vendasData.push({
                  id: doc.id,
                  ...doc.data(),
                  date: new Date(currentYear, month - 1, day)
                });
              });
            }
          }
          setVendasData(vendasData);
          processarVendasPorCategoria(vendasData);
          processarVendasMensais(vendasData);
        } catch (error) {
          console.error("Erro ao buscar dados de vendas:", error);
          setError("Erro ao buscar dados de vendas.");
        }
      }
    };

    const fetchEstoqueData = async () => {
      if (empresaId) {
        const db = getFirestore();
        try {
          const estoqueCollectionRef = collection(
            db,
            `EMPRESAS/${empresaId}/Cadastro de Peças/dados/Estoque Ativo`
          );
          const estoqueSnapshot = await getDocs(estoqueCollectionRef);
          const estoqueList = estoqueSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setEstoqueData(estoqueList);
        } catch (error) {
          console.error("Erro ao buscar dados de estoque:", error);
          setError("Erro ao buscar dados de estoque.");
        }
      }
    };

    fetchEmpresaData();
    fetchVendasData();
    fetchEstoqueData();
  }, [empresaId]);

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate(`/${empresaId}/login`);
    } catch (error) {
      console.error("Erro ao sair:", error);
      setLogoutError("Falha ao sair. Por favor, tente novamente.");
    }
  };

  const processarVendasData = vendas => {
    const vendasPorDia = vendas.reduce((acc, venda) => {
      const dataVenda = venda.date.toLocaleDateString();
      const valorTotal = venda.valorRecebidoPelaVenda;

      if (!acc[dataVenda]) {
        acc[dataVenda] = 0;
      }
      acc[dataVenda] += valorTotal;
      return acc;
    }, {});

    const vendasArray = Object.keys(vendasPorDia).map(data => ({
      dataVenda: data,
      valorTotal: vendasPorDia[data]
    }));

    return vendasArray;
  };

  const processarEstoqueData = estoque => {
    const dataProcessada = estoque.map(item => ({
      produto: item.descricao,
      quantidade: item.quantidade
    }));

    return dataProcessada;
  };

  const processarVendasPorCategoria = vendas => {
    const categorias = vendas.reduce((acc, venda) => {
      const categoria = venda.categoria || "Outros";
      const valorTotal = venda.valorRecebidoPelaVenda;

      if (!acc[categoria]) {
        acc[categoria] = 0;
      }
      acc[categoria] += valorTotal;
      return acc;
    }, {});

    const categoriasArray = Object.keys(categorias).map(categoria => ({
      categoria,
      valorTotal: categorias[categoria]
    }));

    setVendasPorCategoria(categoriasArray);
  };

  const processarVendasMensais = vendas => {
    const meses = vendas.reduce((acc, venda) => {
      const mes = venda.date.getMonth() + 1;
      const valorTotal = venda.valorRecebidoPelaVenda;

      if (!acc[mes]) {
        acc[mes] = 0;
      }
      acc[mes] += valorTotal;
      return acc;
    }, {});

    const mesesArray = Object.keys(meses).map(mes => ({
      mes: `Mês ${mes}`,
      valorTotal: meses[mes]
    }));

    setVendasMensais(mesesArray);
  };

  const vendasFormatadas = processarVendasData(vendasData);
  const estoqueFormatado = processarEstoqueData(estoqueData);

  if (isLoading) {
    return (
      <Container maxWidth="xl" style={{ paddingLeft: isLargeScreen ? "250px" : "0px" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container
      maxWidth="xl"
      style={{ paddingLeft: isLargeScreen ? "250px" : "0px" }}
    >
      
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Bem-vindo ao Dashboard
          </Typography>
          <Typography variant="h6">Empresa: {empresaData.nome}</Typography>
          <Typography>Endereço: {empresaData.endereco}</Typography>
          <Typography>Telefone: {empresaData.telefone}</Typography>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
          >
            {logoutError && (
              <Typography color="error">{logoutError}</Typography>
            )}
            Sair
          </Button>
        </Grid>

        {/* Card para Vendas Totais */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Vendas Totais
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={vendasFormatadas}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dataVenda" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="valorTotal"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Card para Estoque */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Estoque
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={estoqueFormatado}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="produto" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="quantidade" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Card para Total de Vendas por Período */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Total de Vendas por Período
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={vendasFormatadas}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dataVenda" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="valorTotal" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Card para Vendas por Categoria */}
        <Grid item xs={12} md={6}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Vendas por Categoria
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={vendasPorCategoria}
                  dataKey="valorTotal"
                  nameKey="categoria"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {vendasPorCategoria.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Card para Comparação de Vendas Mensais */}
        <Grid item xs={12}>
          <Paper style={{ padding: "20px" }}>
            <Typography variant="h6" gutterBottom>
              Comparação de Vendas Mensais
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={vendasMensais}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="mes" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="valorTotal"
                  stroke="#82ca9d"
                />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DashboardPage;

import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  collection,
  getDocs,
  setDoc,
  query,
  where,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { useTheme } from "@mui/material/styles";
import { db, storage } from "./firebase";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { jsPDF } from "jspdf";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import debounce from 'lodash/debounce';

const Cadastrodefornecedor = () => {
  const theme = useTheme();
  const [fornecedor, setFornecedor] = useState({
    id: "",
    nome: "",
    contato: "",
    cpfCnpj: "",
    chavePix: "",
    agencia: "",
    conta: "",
    email: "",
    porcentagem: "", // Campo para porcentagem de comissão
  });
  const [contractText, setContractText] = useState(""); // Definindo o estado do contrato aqui
  const [senha, setSenha] = useState("");
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [fornecedoresEncontrados, setFornecedoresEncontrados] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedValue =
      name === "nome" || name === "contato" || name === "chavePix"
        ? value.toUpperCase()
        : value;
    setFornecedor((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const validateData = () => {
    if (!fornecedor.nome || !fornecedor.contato || !fornecedor.cpfCnpj) {
      setError("Por favor, preencha todos os campos obrigatórios.");
      return false;
    }
    return true;
  };
  // Função debounce para evitar múltiplas chamadas da função
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  useEffect(() => {
    if (fornecedor.nome.trim().length > 0) {
      const debouncedBusca = debounce(async () => {
        setLoading(true);
        try {
          const startAtNome = fornecedor.nome.toUpperCase();
          const endAtNome = startAtNome + "\uf8ff";

          const q = query(
            collection(db, "EMPRESAS", empresaId, "Cadastro de fornecedores"),
            where("nome", ">=", startAtNome),
            where("nome", "<=", endAtNome)
          );

          const querySnapshot = await getDocs(q);
          const resultados = [];

          querySnapshot.forEach((doc) => {
            resultados.push({ id: doc.id, ...doc.data() });
          });

          setFornecedoresEncontrados(resultados);
        } catch (error) {
          setError("Erro ao buscar fornecedores: " + error.message);
        } finally {
          setLoading(false);
        }
      }, 500); // Tempo de debounce de 500ms

      debouncedBusca();

      // Limpeza para cancelar o debounce ao desmontar o componente
      return () => debouncedBusca.cancel && debouncedBusca.cancel();
    } else {
      setFornecedoresEncontrados([]);
    }
  }, [fornecedor.nome, db, empresaId]);



 

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateData()) {
      return;
    }

    setLoading(true);
    let fornecedorRef;

    if (isEditing && fornecedor.id) {
      fornecedorRef = doc(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de fornecedores",
        fornecedor.id
      );
    } else {
      const fornecedorId = fornecedor.nome
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();

      fornecedorRef = doc(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de fornecedores",
        fornecedorId
      );

      setFornecedor((prev) => ({ ...prev, id: fornecedorId }));
      console.log("Novo fornecedor criado com ID:", fornecedorId);
    }

    try {
      await setDoc(
        fornecedorRef,
        { ...fornecedor, id: fornecedor.id || fornecedorRef.id, porcentagem: fornecedor.porcentagem },
        { merge: true }
      );

      await gerarESalvarContrato(fornecedorRef);

      if (!isEditing) {
        await createSubcollectionsForFornecedor(fornecedorRef);
      }

      setOpenSnackbar(true);
      setSnackbarMessage(
        isEditing
          ? "Fornecedor atualizado com sucesso!"
          : "Fornecedor cadastrado com sucesso!"
      );

      limparFormulario();
      setIsEditing(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  const selecionarParaEdicao = (fornecedorSelecionado) => {
    console.log("Fornecedor selecionado para edição:", fornecedorSelecionado);
    if (fornecedorSelecionado.id) {
      setFornecedor(fornecedorSelecionado);
      setIsEditing(true);
      setError(""); // Limpa qualquer erro anterior
    } else {
      console.error("Fornecedor selecionado não tem ID:", fornecedorSelecionado);
    }
  };


  const handleDelete = async () => {
    console.log("Tentativa de excluir fornecedor:", fornecedor);

    if (!fornecedor.id) {
      setError("Nenhum fornecedor selecionado para exclusão.");
      console.error("Erro: Nenhum fornecedor selecionado para exclusão.");
      return;
    }

    try {
      setLoading(true);
      const fornecedorRef = doc(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de fornecedores",
        fornecedor.id
      );
      console.log("Referência do fornecedor para exclusão:", fornecedorRef);

      await deleteDoc(fornecedorRef);
      console.log("Fornecedor excluído com sucesso!");

      setSnackbarMessage("Fornecedor excluído com sucesso!");
      setOpenSnackbar(true);
      limparFormulario();
    } catch (error) {
      console.error("Erro ao excluir fornecedor:", error);
      setError("Erro ao excluir fornecedor. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const limparFormulario = () => {
    console.log("Limpando o formulário...");
    setFornecedor({
      id: "",
      nome: "",
      contato: "",
      cpfCnpj: "",
      chavePix: "",
      agencia: "",
      conta: "",
      email: "",
      porcentagem: "" // Limpar campo porcentagem
    });
    setIsEditing(false);
    setError("");
  };
    


  const fetchContractText = async () => {
    try {
      console.log("Buscando contrato na coleção da empresa:", empresaId);
      const contratoRef = collection(db, "EMPRESAS", empresaId, "Contratos");
      const contratoSnapshot = await getDocs(contratoRef);
      let contractText = null;

      contratoSnapshot.forEach((doc) => {
        if (doc.exists()) {
          console.log("Documento de contrato encontrado:", doc.id, doc.data());
          contractText = doc.data().contrato_fornecedor;
        }
      });

      if (!contractText) {
        console.error("Nenhum contrato encontrado na empresa:", empresaId);
        throw new Error("Texto de contrato não encontrado.");
      }

      return contractText;
    } catch (error) {
      console.error("Erro ao buscar texto de contrato:", error);
      return "Texto padrão do contrato não encontrado.";
    }
  };

  const gerarESalvarContrato = async (fornecedorRef) => {
    try {
      const contractText = await fetchContractText();

      if (!contractText || contractText === "Texto padrão do contrato não encontrado.") {
        throw new Error("Texto de contrato não disponível.");
      }

      const docPdf = new jsPDF();

      const pageWidth = docPdf.internal.pageSize.getWidth();
      const pageHeight = docPdf.internal.pageSize.getHeight();
      const margin = 20;
      const maxLineWidth = pageWidth - margin * 2;
      const lineHeight = 10;
      let cursorY = margin;

      // Adicionar data do contrato
      const dataAtual = new Date().toLocaleDateString();
      docPdf.setFontSize(12);
      docPdf.setFont("helvetica", "normal");
      docPdf.text(`Data: ${dataAtual}`, margin, cursorY);
      cursorY += lineHeight * 2;

      let textoPersonalizado = contractText
        .replace("{nomeFornecedor}", "NOME_DO_FORNECEDOR_SUBSTITUTO")
        .replace("{cpfCnpj}", "CPF_SUBSTITUTO")
        .replace("{contato}", "CONTATO_SUBSTITUTO")
        .replace("{email}", "EMAIL_SUBSTITUTO")
        .replace("{porcentagem}", "PORCENTAGEM_SUBSTITUTA")
        .replace("{porcentagemConsignataria}", (100 - parseInt(fornecedor.porcentagem, 10)) + "%");

      // Adicionar título em negrito
      docPdf.setFontSize(16);
      docPdf.setFont("helvetica", "bold");
      docPdf.text(`Contrato de Consignação`, margin, cursorY);
      cursorY += lineHeight * 2;

      docPdf.setFontSize(12);
      docPdf.setFont("helvetica", "normal");

      // Adicionar parágrafos e formatar campos em negrito
      const paragrafos = textoPersonalizado.split("\n\n");

      paragrafos.forEach((paragrafo) => {
        let linhas = docPdf.splitTextToSize(paragrafo, maxLineWidth);

        // Processar cada linha e adicionar negrito nos campos relevantes
        linhas = linhas.map((linha) => {
          return linha
            .replace("NOME_DO_FORNECEDOR_SUBSTITUTO", fornecedor.nome)
            .replace("CPF_SUBSTITUTO", fornecedor.cpfCnpj)
            .replace("CONTATO_SUBSTITUTO", fornecedor.contato)
            .replace("EMAIL_SUBSTITUTO", fornecedor.email)
            .replace("PORCENTAGEM_SUBSTITUTA", fornecedor.porcentagem + "%");
        });

        linhas.forEach((linha, index) => {
          if (cursorY + lineHeight > pageHeight - margin) {
            docPdf.addPage();
            cursorY = margin;
          }

          // Verifica se a linha contém algum dos campos que precisam de negrito
          if (
            linha.includes(fornecedor.nome) ||
            linha.includes(fornecedor.cpfCnpj) ||
            linha.includes(fornecedor.contato) ||
            linha.includes(fornecedor.email) ||
            linha.includes(fornecedor.porcentagem + "%")
          ) {
            docPdf.setFont("helvetica", "bold");
            docPdf.text(linha, margin, cursorY);
            docPdf.setFont("helvetica", "normal");
          } else {
            docPdf.text(linha, margin, cursorY);
          }
          cursorY += lineHeight;
        });

        cursorY += lineHeight;
      });

      const pdfBlob = docPdf.output("blob");

      // Salvando o PDF no Firestore Storage na pasta da empresa
      const pdfRef = ref(storage, `${empresaId}/contratos_fornecedores/Contrato_${fornecedor.nome}.pdf`);
      await uploadBytes(pdfRef, pdfBlob);

      // Obtendo a URL de download do PDF e salvando no Firestore
      const pdfURL = await getDownloadURL(pdfRef);
      await setDoc(fornecedorRef, { contratoUrl: pdfURL }, { merge: true });

      console.log("Contrato salvo com sucesso no Firestore.");

      // Baixar o PDF automaticamente no navegador
      docPdf.save(`Contrato_${fornecedor.nome}.pdf`);
    } catch (error) {
      console.error("Erro ao gerar e salvar o contrato:", error);
    }
  };

  const createSubcollectionsForFornecedor = async (fornecedorRef) => {
    const initialData = { createdAt: new Date() };
    const subcollections = ["Conta", "EstoqueAtivo", "EstoqueFinalizado"];

    for (const subcollection of subcollections) {
      await setDoc(doc(fornecedorRef, subcollection, "initial"), initialData);
    }
  };

  const handleChangeCPF = (event) => {
    const { value } = event.target;
    setFornecedor((prev) => ({ ...prev, cpfCnpj: mascaraCPF(value) }));
  };

  const mascaraCPF = (valor) => {
    return valor
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const handleChangeTelefone = (event) => {
    const { value } = event.target;
    const valorFormatado = mascaraTelefone(value.replace("+55 ", ""));
    setFornecedor((prev) => ({ ...prev, contato: valorFormatado }));
  };

  const mascaraTelefone = (valor) => {
    let apenasNumeros = valor.replace(/\D/g, "");
    if (apenasNumeros.length > 11) {
      apenasNumeros = apenasNumeros.substr(0, 11);
    }

    return (
      "+55 " +
      apenasNumeros.replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d{5})(\d)/, "$1-$2")
    );
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleConfirmarSenha = async () => {
    try {
      const isAuthenticated = await reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(auth.currentUser.email, senha)
      );
      if (!isAuthenticated) {
        throw new Error("Senha incorreta.");
      }
    } catch (error) {
      setError("Senha incorreta.");
    }
    handleCloseModal();
  };

return (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      padding: isMdUp ? '20px 250px' : '20px',
    }}
  >
    <Paper elevation={6} sx={{ flex: 1, p: 4 }}>
      <Typography component="h1" variant="h5" align="center">
        Cadastro e Consulta de Fornecedor
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        {error && <Alert severity="error">{error}</Alert>}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome do Fornecedor"
              name="nome"
              autoComplete="nome"
              autoFocus
              value={fornecedor.nome}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="contato"
              label="Contato (Telefone)"
              name="contato"
              autoComplete="contato"
              value={fornecedor.contato}
              onChange={handleChangeTelefone}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="cpfCnpj"
              label="CPF/CNPJ"
              name="cpfCnpj"
              autoComplete="cpf-cnpj"
              value={fornecedor.cpfCnpj}
              onChange={handleChangeCPF}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail do Fornecedor"
              name="email"
              autoComplete="email"
              value={fornecedor.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              fullWidth
              id="chavePix"
              label="Chave Pix"
              name="chavePix"
              autoComplete="chave-pix"
              value={fornecedor.chavePix}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              id="agencia"
              label="Agência"
              name="agencia"
              autoComplete="agencia"
              value={fornecedor.agencia}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              id="conta"
              label="Conta Corrente"
              name="conta"
              autoComplete="conta-corrente"
              value={fornecedor.conta}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="porcentagem"
              label="Porcentagem de Comissão (%)"
              name="porcentagem"
              type="number"
              autoComplete="porcentagem"
              value={fornecedor.porcentagem}
              onChange={handleChange}
            />
          </Grid>

          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogTitle>Confirmar Senha</DialogTitle>
            <DialogContent>
              <form>
                <TextField
                  autoFocus
                  margin="dense"
                  id="senha"
                  label="Senha"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  autoComplete="current-password"
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal}>Cancelar</Button>
              <Button onClick={handleConfirmarSenha}>Confirmar</Button>
            </DialogActions>
          </Dialog>
          {fornecedoresEncontrados.length > 0 && (
            <Paper
              elevation={2}
              sx={{ maxHeight: 300, overflow: "auto", mt: 2, mb: 2 }}
            >
              <List dense>
                {fornecedoresEncontrados.map((fornecedor) => (
                  <ListItem
                    key={fornecedor.id}
                    button
                    onClick={() => selecionarParaEdicao(fornecedor)}
                  >
                    <ListItemText
                      primary={fornecedor.nome}
                      secondary={`CPF/CNPJ: ${fornecedor.cpfCnpj || "Não informado"}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant={isEditing ? "outlined" : "contained"}
              color={isEditing ? "primary" : "secondary"}
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : isEditing ? (
                "Editar"
              ) : (
                "Cadastrar"
              )}
            </Button>
            {isEditing && (
              <Button
                fullWidth
                variant="contained"
                color="error"
                sx={{ mt: 1, mb: 2 }}
                disabled={loading}
                onClick={handleDelete}
              >
                Excluir Fornecedor
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>

    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
    />
  </Box>
);
};
export default Cadastrodefornecedor;
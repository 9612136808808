import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from "@mui/material";

const ComponentToPrint = forwardRef(
  ({ produtosFiltrados, nomeEmpresa }, ref) => {
    const dataAtual = new Date();
    const dataFormatada = `${dataAtual.toLocaleDateString("pt-BR", {
      year: "numeric",
      month: "long",
      day: "numeric"
    })} às ${dataAtual.toLocaleTimeString("pt-BR")}`;

    return (
      <div
        ref={ref}
        style={{ width: "100%", padding: "20mm", boxSizing: "border-box" }}
      >
        {/* Estilos para impressão em modo paisagem e otimização para A4 */}
        <style>
          {`
          @page {
            size: A4 landscape;
            margin: 10mm;
          }
          body {
            margin: 0;
          }
          .MuiTableCell-root {
            font-size: 10pt; /* Ajuste conforme necessário */
          }
        `}
        </style>

        {/* Cabeçalho do relatório */}
        <header style={{ marginBottom: 20, textAlign: "center" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Relatório de Estoque
          </Typography>
          <Typography variant="h4" gutterBottom style={{ marginTop: 30 }}>
            {nomeEmpresa}
          </Typography>
          <Typography variant="body1" style={{ marginTop: 5 }}>
            Gerado em: {dataFormatada}
          </Typography>
        </header>

        {/* Tabela de produtos */}
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "auto" }}
            aria-label="estoque table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Marca</TableCell>
                <TableCell>Fornecedor</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Tamanho</TableCell>
                <TableCell>Categoria</TableCell>
                <TableCell>Valor de Venda</TableCell>
                <TableCell>Data de Entrada</TableCell>
                <TableCell>Quantidade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {produtosFiltrados.map((produto, index) => (
                <TableRow key={index}>
                  <TableCell>{produto.marca}</TableCell>
                  <TableCell>{produto.fornecedor || "N/A"}</TableCell>
                  <TableCell>{produto.descricao}</TableCell>
                  <TableCell>{produto.tamanho}</TableCell>
                  <TableCell>{produto.categoria}</TableCell>
                  <TableCell>{`R$ ${produto.valorVenda}`}</TableCell>
                  <TableCell>
                    {new Date(produto.dataEntrada).toLocaleDateString("pt-BR")}
                  </TableCell>
                  <TableCell>{produto.quantidade}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
);

const RelatorioEstoque = forwardRef(
  ({ produtosFiltrados, nomeEmpresa }, ref) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => console.log("Impressão concluída!")
    });

    useImperativeHandle(ref, () => ({
      handlePrint
    }));

    return (
      <div>
        <ComponentToPrint
          ref={componentRef}
          produtosFiltrados={produtosFiltrados}
          nomeEmpresa={nomeEmpresa}
        />
        {/* Botão de impressão gerenciado externamente */}
      </div>
    );
  }
);

export default RelatorioEstoque;

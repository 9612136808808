import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { format } from "date-fns";
import {
  Container,
  Typography,
  CircularProgress,
  Modal,
  TextField,
  Box,
  Grid,
  Checkbox,
  MenuItem,
  FormControl,
  FormControlLabel,
  Card,
  Switch,
  useMediaQuery,
  CardContent,
  Table,
  Select,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@mui/material";
import {
  Payment as PaymentIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  EventNote as EventNoteIcon,
  AccessTime as AccessTimeIcon,
  AttachFile as AttachFileIcon,
  Email as EmailIcon,
  PictureAsPdf as PictureAsPdfIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Receipt as ReceiptIcon,
  Close as CloseIcon,
  Assignment as AssignmentIcon,
  CalendarToday as CalendarTodayIcon
} from "@mui/icons-material";

import { ThemeProvider, StyledEngineProvider, createTheme, useTheme } from "@mui/material/styles";

import { jsPDF } from "jspdf";
import "jspdf-autotable";

const EstiloModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto", // Ajustado para 'auto' para acomodar o conteúdo
  maxWidth: "90%", // Limita a largura máxima para evitar a saída da tela
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowX: "auto" // Permite a rolagem horizontal se necessário
};
// Mova esta função para o início, antes de qualquer referência a ela
const verificarValor = valor => {
  // Verifica se o valor está definido e não é nulo
  if (valor !== undefined && valor !== null && valor !== "") {
    // Se for um número, formata como valor monetário
    if (typeof valor === "number") {
      return `R$ ${valor.toFixed(2)}`;
    }
    // Para outros tipos de dados, retorna o valor como está
    return valor;
  }
  // Se o valor estiver ausente, retorna "N/A"
  return "N/A";
};
const pdfBlobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const enviarEmailComPDF = async (
  fornecedor,
  pdfBlob,
  empresaId,
  setSnackbarMessage,
  setOpenSnackbar,
  setSendingEmail
) => {
  const fornecedorRef = doc(
    db,
    "EMPRESAS",
    empresaId,
    "Cadastro de fornecedores",
    fornecedor.id
  );
  const fornecedorDoc = await getDoc(fornecedorRef);

  if (!fornecedorDoc.exists()) {
    setSnackbarMessage("Erro: Fornecedor não encontrado.");
    setOpenSnackbar(true);
    setSendingEmail(false);
    return;
  }

  const fornecedorData = fornecedorDoc.data();
  const fornecedorEmail = fornecedorData.email;

  if (!fornecedorEmail) {
    setSnackbarMessage("Erro: Fornecedor não possui email cadastrado.");
    setOpenSnackbar(true);
    setSendingEmail(false);
    return;
  }

  const attachmentBase64 = await pdfBlobToBase64(pdfBlob);

  const mailData = {
    to: fornecedorEmail,
    subject: `Recibo de Pagamento ${empresaId} para o fornecedor ${fornecedor.nome}`,
    text: "Segue em anexo o recibo de pagamento.",
    attachment: attachmentBase64,
    filename: `Recibo_${empresaId}_${fornecedor.nome}.pdf`
  };

  try {
    const response = await fetch(
      "https://us-central1-dona-cora.cloudfunctions.net/sendEmailWithPDF",
      {
        method: "POST",
        body: JSON.stringify(mailData),
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    if (!response.ok) {
      const responseText = await response.text();
      console.error("Response text:", responseText);
      throw new Error("Erro ao enviar email.");
    } else {
      setSnackbarMessage("Email enviado com sucesso!");
      setOpenSnackbar(true);
    }
  } catch (error) {
    console.error("Error:", error);
    setSnackbarMessage("Erro ao enviar email: " + error.message);
    setOpenSnackbar(true);
  } finally {
    setSendingEmail(false);
  }
};
const FinanceiroFornecedores = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [fornecedores, setFornecedores] = useState([]);
  const [vendasFornecedor, setVendasFornecedor] = useState([]);
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedVenda, setSelectedVenda] = useState(null); // Estado para a venda selecionada
  const [openModal, setOpenModal] = useState(false); // Estado para controlar a abertura do modal
  const [mostrarModalRecibo, setMostrarModalRecibo] = useState(false);
  const [filtrarVendas20Dias, setFiltrarVendas20Dias] = useState(false);
  const [mostrarRecibo, setMostrarRecibo] = useState(false);
  const [usarFiltradas, setUsarFiltradas] = useState(false);
  const [mostrarModalEscolhaFormato, setMostrarModalEscolhaFormato] = useState(
    false
  );
  const [dadosRecibo, setDadosRecibo] = useState([]);
  const [fornecedorIdSelecionado, setFornecedorIdSelecionado] = useState("");
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [etapaAtual, setEtapaAtual] = useState("pagamento");
  const [dataInicio, setDataInicio] = useState("");
  const [observacao, setObservacao] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [formatoRelatorio, setFormatoRelatorio] = useState("");
  const [mostrarOpcoesRecibo, setMostrarOpcoesRecibo] = useState(false);
  const [dataFim, setDataFim] = useState("");
  const [selecionados, setSelecionados] = useState([]);
  const [openPagamentoModal, setOpenPagamentoModal] = useState(false);
  const [deveGerarRecibo, setDeveGerarRecibo] = useState(false);
  const [arquivoAnexo, setArquivoAnexo] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);

  useEffect(() => {
    const carregarFornecedores = async () => {
      setLoading(true);
      const fornecedoresRef = collection(
        db,
        `EMPRESAS/${empresaId}/Cadastro de fornecedores`
      );
      const querySnapshot = await getDocs(fornecedoresRef);
      const fornecedoresList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setFornecedores(fornecedoresList);
      setLoading(false);
    };
    carregarFornecedores();
  }, [empresaId]);

  const carregarVendasFornecedor = async (
    fornecedorId,
    dataInicio,
    dataFim,
    incluirDataFim = true
  ) => {
    setLoading(true);

    // Converter string para objeto Date e definir a hora para o início do dia (00:00:00)
    const dataInicioDate = dataInicio ? new Date(dataInicio) : new Date(0);
    dataInicioDate.setHours(0, 0, 0, 0);

    // Converter string para objeto Date e definir a hora para o fim do dia (23:59:59) se incluirDataFim for true
    const dataFimDate = dataFim ? new Date(dataFim) : new Date();
    if (incluirDataFim) {
      dataFimDate.setHours(23, 59, 59, 999);
    }

    const vendasRef = collection(
      db,
      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Conta`
    );
    const vendasSnapshot = await getDocs(vendasRef);
    const vendasList = vendasSnapshot.docs
      .map(doc => {
        const vendaData = doc.data();
        vendaData.dataVenda = vendaData.dataVenda
          ? new Date(vendaData.dataVenda.seconds * 1000)
          : null;
        // Verificação para garantir que `produtos` seja um array
        vendaData.produtos = Array.isArray(vendaData.produtos) 
          ? vendaData.produtos.map(produto => ({
              ...produto,
              valorVenda: produto.valorVenda ? produto.valorVenda.toString() : "0"
            }))
          : [];
        return { id: doc.id, ...vendaData };
      })
      .filter(
        venda =>
          venda.dataVenda &&
          venda.dataVenda >= dataInicioDate &&
          venda.dataVenda <= dataFimDate
      );

    setVendasFornecedor(vendasList);
    setLoading(false);
  };

  const handleChangeFornecedor = event => {
    const fornecedorId = event.target.value;
    const fornecedor = fornecedores.find(f => f.id === fornecedorId);
    setFornecedorSelecionado(fornecedor); // Correção aqui
    carregarVendasFornecedor(fornecedorId);
  };
  const handleGerarReciboClick = async () => {
    setSendingEmail(true);
    const doc = new jsPDF();
    doc.setFont("helvetica");
    doc.setFontSize(10);

    let currentY = 20;

    doc.setFontSize(16).setFont(undefined, "bold");
    doc.text(`Empresa: ${empresaId}`, 105, currentY, null, null, "center");
    currentY += 10;

    doc.setFontSize(14);
    doc.text("Recibo de Pagamento", 105, currentY, null, null, "center");
    currentY += 10;

    doc.setFontSize(10);
    doc.text(
      `ID do Pagamento: ${dadosRecibo[0]?.idPagamento || "N/A"}`,
      20,
      currentY
    );
    currentY += 5;
    doc.text(
      `Data de Pagamento: ${new Date().toLocaleDateString()}`,
      20,
      currentY
    );
    currentY += 5;
    doc.text(
      `Fornecedor: ${fornecedorSelecionado?.nome || "N/A"}`,
      20,
      currentY
    );
    currentY += 5;
    doc.text(`Método de Pagamento: ${formaPagamento || "N/A"}`, 20, currentY);
    currentY += 5;

    if (observacao) {
      doc.text(`Observação: ${observacao}`, 20, currentY);
      currentY += 5;
    }

    let totalRepassadoGeral = 0;

    dadosRecibo.forEach(venda => {
      doc.setFont(undefined, "bold");
      doc.text(`ID Venda: ${venda.idVenda || "N/A"}`, 20, currentY);
      currentY += 5;

      if (Array.isArray(venda.produtos) && venda.produtos.length > 0) {
        const titles = [
          "Entrada Produto",
          "Valor Produto",
          "Quantidade",
          "Desconto",
          "Total",
          "Valor Pago"
        ];
        const columnStarts = [25, 55, 80, 120, 150, 180];
        doc.setFontSize(10).setFont(undefined, "normal");

        titles.forEach((title, index) => {
          doc.text(title, columnStarts[index], currentY, { align: "left" });
        });
        currentY += 5;

        venda.produtos.forEach(produto => {
          const produtoDetalhes = [
            produto.descricao || "N/A",
            formatCurrency(parseFloat(produto.valorVenda)),
            produto.quantidade.toString() || "0",
            `${produto.desconto}` || "0",
            formatCurrency(parseFloat(produto.precoFinal)),
            formatCurrency(parseFloat(produto.repasseFornecedor))
          ];


          produtoDetalhes.forEach((detail, i) => {
            doc.text(detail, columnStarts[i], currentY, { align: "left" });
          });
          currentY += 5;

          totalRepassadoGeral += parseFloat(produto.repasseFornecedor) || 0;
        });
      } else {
        console.error("Venda sem produtos ou 'produtos' não é um array.");
      }
    });

    doc.setFont(undefined, "bold");
    doc.text(
      `Total Pago ao Fornecedor: ${formatCurrency(totalRepassadoGeral)}`,
      20,
      currentY
    );

    const pdfBlob = doc.output("blob");

    await enviarEmailComPDF(
      fornecedorSelecionado,
      pdfBlob,
      empresaId,
      setSnackbarMessage,
      setOpenSnackbar,
      setSendingEmail
    );
  };

  const gerarReciboPDF = () => {
    if (!Array.isArray(dadosRecibo) || dadosRecibo.length === 0) {
      console.error("dadosRecibo está vazio ou não é um array.");
      return; // Interrompe a execução da função se não houver dados
    }

    const doc = new jsPDF();
    doc.setFont("helvetica");
    doc.setFontSize(10);

    let currentY = 20; // Posição inicial Y para o cabeçalho do recibo

    // Cabeçalho do recibo
    doc.setFontSize(16).setFont(undefined, "bold");
    doc.text(`Empresa: ${empresaId}`, 105, currentY, null, null, "center");
    currentY += 10;

    doc.setFontSize(14);
    doc.text("Recibo de Pagamento", 105, currentY, null, null, "center");
    currentY += 10;

    // Informações do recibo
    doc.setFontSize(10);
    doc.text(
      `ID do Pagamento: ${dadosRecibo[0]?.idPagamento || "N/A"}`,
      20,
      currentY
    );
    currentY += 5;
    doc.text(
      `Data de Pagamento: ${new Date().toLocaleDateString()}`,
      20,
      currentY
    );
    currentY += 5;
    doc.text(
      `Fornecedor: ${fornecedorSelecionado?.nome || "N/A"}`,
      20,
      currentY
    );
    currentY += 5;
    doc.text(`Método de Pagamento: ${formaPagamento || "N/A"}`, 20, currentY);
    currentY += 5;

    // Observação, se houver
    if (observacao) {
      doc.text(`Observação: ${observacao}`, 20, currentY);
      currentY += 5;
    }

    let totalRepassadoGeral = 0; // Total repassado para todos os fornecedores

    // Iteração sobre cada venda
    dadosRecibo.forEach(venda => {
      // Exibe o ID Venda uma vez para cada grupo de produtos
      doc.setFont(undefined, "bold");
      doc.text(`ID Venda: ${venda.idVenda || "N/A"}`, 20, currentY);
      currentY += 5;

      if (Array.isArray(venda.produtos) && venda.produtos.length > 0) {
        // Títulos das colunas para produtos
        const titles = [
          "Entrada Produto",
          "Valor Produto",
          "Quantidade",
          "Desconto",
          "Total",
          "Valor Pago"
        ];
        const columnStarts = [25, 55, 80, 120, 150, 180]; // Ajuste as posições conforme necessário
        doc.setFontSize(10).setFont(undefined, "normal");

        titles.forEach((title, index) => {
          doc.text(title, columnStarts[index], currentY, { align: "left" });
        });
        currentY += 5; // Espaço antes de começar a listar os produtos

        venda.produtos.forEach(produto => {
          const produtoDetalhes = [
            produto.dataEntrada || "N/A", // Supondo que você queira mostrar a data de entrada como data de venda
            formatCurrency(parseFloat(produto.valorVenda)),
            produto.quantidade.toString() || "0",
            `${produto.desconto}` || "0",
            formatCurrency(parseFloat(produto.precoFinal)),
            formatCurrency(parseFloat(produto.repasseFornecedor))
          ];

          produtoDetalhes.forEach((detail, i) => {
            doc.text(detail, columnStarts[i], currentY, { align: "left" });
          });
          currentY += 5; // Aumenta o Y para o próximo produto

          totalRepassadoGeral += parseFloat(produto.repasseFornecedor) || 0;
        });
      } else {
        console.error("Venda sem produtos ou 'produtos' não é um array.");
      }
    });

    // Após todas as vendas, imprime o total pago ao fornecedor
    doc.setFont(undefined, "bold");
    doc.text(
      `Total Pago ao Fornecedor: ${formatCurrency(totalRepassadoGeral)}`,
      20,
      currentY
    );

    // Salva o PDF
    const dataPagamentoFormatada = new Date()
      .toLocaleDateString("pt-BR")
      .replace(/\//g, "-");
    const nomeArquivo = `Recibo_${fornecedorSelecionado.nome}_${dataPagamentoFormatada}.pdf`;
    doc.save(nomeArquivo);
  };

  // Função para garantir que a string pode ter .replace() chamado sobre ela
  const safeReplace = (str, pattern, replacement) => {
    if (str === undefined || str === null) {
      return ""; // ou algum valor padrão que faça sentido para seu contexto
    }
    return str.replace(pattern, replacement);
  };
  const gerarRelatorioFornecedor = (usarFiltradas = false, formato = "PDF") => {
    const vendasParaRelatorio = usarFiltradas
      ? vendasFiltradas
      : vendasFornecedor;

    const valorTotalRepassado = vendasParaRelatorio.reduce(
      (acc, venda) => acc + Number(venda.totalCaixaFornecedor || 0),
      0
    );

    if (formato === "PDF") {
      const doc = new jsPDF();
      const dataEmissao = new Date().toLocaleDateString("pt-BR");

      doc.setFontSize(12);
      doc.text(`Empresa: ${empresaId}`, 10, 10);
      doc.text(`Data de Emissão: ${dataEmissao}`, 10, 16);

      const colunas = [
        { title: "ID Venda", dataKey: "idVenda" },
        { title: "ID Pagamento", dataKey: "idPagamento" },
        { title: "Descrição do Produto", dataKey: "descricao" },
        { title: "Data da Venda", dataKey: "dataVenda" },
        { title: "Status de Pagamento", dataKey: "statusPagamento" },
        { title: "Valor Repassado", dataKey: "totalCaixaFornecedor" }
      ];

      const dados = vendasParaRelatorio.flatMap(venda =>
        venda.produtos.map(produto => ({
          idVenda: venda.idVenda,
          idPagamento: venda.idPagamento,
          descricao: produto.descricao,
          dataVenda: format(new Date(venda.dataVenda), "dd/MM/yyyy"),
          statusPagamento: venda.statusPagamento,
          totalCaixaFornecedor: `R$ ${parseFloat(
            produto.repasseFornecedor
          ).toFixed(2)}`
        }))
      );

      doc.autoTable(colunas, dados, { startY: 30 });

      doc.setFontSize(10);
      doc.text(
        `Valor Total a ser Repassado: R$ ${valorTotalRepassado.toFixed(2)}`,
        10,
        doc.lastAutoTable.finalY + 10
      );

      const nomeArquivo = `Relatorio_Vendas_${fornecedorSelecionado.nome ||
        ""}_${dataEmissao.replace(/\//g, "-")}.pdf`;
      doc.save(nomeArquivo);
    } else if (formato === "CSV") {
      exportarDadosParaCSV(vendasParaRelatorio);
    }
  };

  const formatarLinhaCSV = dados => {
    // Assegurar que todos os números grandes sejam tratados como strings.
    return dados
      .map(dado => {
        if (typeof dado === "number" && !Number.isInteger(dado)) {
          return `"${dado.toFixed(2)}"`;
        }
        if (typeof dado === "number" && dado > 1e9) {
          return `"${dado.toString()}"`;
        }
        return `"${String(dado).replace(/"/g, '""')}"`;
      })
      .join(";");
  };

  const exportarDadosParaCSV = (
    vendasParaRelatorio,
    empresaId,
    nomeFornecedor
  ) => {
    let linhas = [
      [
        "ID Venda",
        "ID Pagamento",
        "Nome do Fornecedor",
        "Descrição do Produto",
        "Data da Venda",
        "Status de Pagamento",
        "Valor Repassado"
      ]
    ];

    // Adicionando dados
    vendasParaRelatorio.forEach(venda => {
      venda.produtos.forEach(produto => {
        const totalCaixaFornecedorFormatado =
          produto.repasseFornecedor !== undefined
            ? produto.repasseFornecedor
                .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                .replace("R$", "R$ ")
            : "N/A";

        const linha = [
          `"${venda.idVenda}"`,
          `"${venda.idPagamento}"`,
          `"${nomeFornecedor}"`,
          produto.descricao,
          format(new Date(venda.dataVenda), "dd/MM/yyyy"),
          venda.statusPagamento,
          totalCaixaFornecedorFormatado
        ];
        linhas.push(linha);
      });
    });

    const csvContent =
      "\uFEFF" + linhas.map(e => formatarLinhaCSV(e)).join("\n");
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `Relatorio_Vendas_${empresaId}_${format(new Date(), "dd-MM-yyyy")}.csv`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const formatCurrency = value => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value);
  };
  // Função auxiliar para garantir que o valor possa ser formatado
  const formatarValor = valor => {
    const num = parseFloat(valor);
    return !isNaN(num) ? num.toFixed(2) : "0.00";
  };
  const selecionarFornecedor = fornecedor => {
    setFornecedorSelecionado(fornecedor.nome);
    carregarVendasFornecedor(fornecedor.id);
  };
  const handleSelect = id => {
    const isSelected = selecionados.includes(id);
    setSelecionados(
      isSelected
        ? selecionados.filter(item => item !== id)
        : [...selecionados, id]
    );
  };
  const abrirDetalhesVenda = venda => {
    console.log("Venda Selecionada para Detalhes: ", venda);
    setSelectedVenda({
      ...venda,
      produtos: venda.produtos.map(produto => ({
        ...produto,
        valorVenda: produto.valorVenda ? produto.valorVenda.toString() : "0"
      }))
    });
    setOpenModal(true);
  };
  const converterTimestampParaDate = dataVenda => {
    // Caso `dataVenda` seja um objeto Timestamp do Firestore
    if (dataVenda && typeof dataVenda.toDate === "function") {
      return dataVenda.toDate();
    }
    // Caso `dataVenda` seja um objeto Date do JavaScript ou uma string
    else if (dataVenda instanceof Date) {
      return dataVenda;
    } else if (typeof dataVenda === "string" || dataVenda instanceof String) {
      // Tentativa de parsear a string de data; isso pode precisar de ajustes baseado no formato específico da string
      return new Date(dataVenda);
    }
    // Fallback: Se o formato de `dataVenda` não for reconhecido
    console.error("Formato de dataVenda não reconhecido:", dataVenda);
    return new Date(); // Retornar a data atual como fallback pode não ser ideal, ajuste conforme necessário
  };

  const realizarUploadAnexo = async arquivo => {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `${empresaId}/anexos de pagamento/${arquivo.name}`
    );

    await uploadBytes(storageRef, arquivo);
    console.log("Arquivo enviado com sucesso");

    const url = await getDownloadURL(storageRef);
    return url; // Retorna a URL do arquivo no storage para ser salva junto ao pagamento
  };
  // Calcula o total selecionado para pagamento
  const calcularTotalSelecionado = () => {
    return selecionados
      .reduce((total, vendaId) => {
        const venda = vendasFornecedor.find(venda => venda.id === vendaId);
        return total + (venda ? Number(venda.totalCaixaFornecedor) || 0 : 0);
      }, 0)
      .toFixed(2);
  };
  const totalSelecionado = calcularTotalSelecionado();
  const realizarPagamento = async () => {
    if (!fornecedorSelecionado || !fornecedorSelecionado.id) {
      console.error("ID do fornecedor não definido.");
      return;
    }

    let urlAnexo = "";
    if (arquivoAnexo) {
      urlAnexo = await realizarUploadAnexo(arquivoAnexo);
    }

    const batch = writeBatch(db);

    // Gera um ID de pagamento baseado no timestamp atual
    const pagamentoId = Date.now().toString(); // Timestamp em milissegundos como string

    // Referência para a coleção de pagamentos do fornecedor
    const fornecedorPagamentosRef = collection(
      db,
      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorSelecionado.id}/Pagamentos`
    );

    const vendasPagasData = [];
    let totalPago = 0;

    for (const vendaId of selecionados) {
      const contaRef = doc(
        db,
        `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorSelecionado.id}/Conta`,
        vendaId
      );
      const docSnap = await getDoc(contaRef);

      if (docSnap.exists()) {
        const vendaData = docSnap.data();

        // Aqui, apenas usamos o valor já existente em `repasseFornecedor`
        const produtosAtualizados = vendaData.produtos.map(produto => ({
          ...produto,
          repasseFornecedor: produto.repasseFornecedor // Usando o valor já existente
        }));

        vendasPagasData.push({
          vendaId: vendaId,
          dataVenda: vendaData.dataVenda,
          produtos: produtosAtualizados,
          statusPagamento: "PAGO",
          observacao: observacao,
          metodoPagamento: formaPagamento
        });

        // Atualiza o documento de venda para refletir o pagamento
        batch.update(contaRef, {
          statusPagamento: "PAGO",
          idPagamento: pagamentoId,
          produtos: produtosAtualizados
        });

        // Atualiza o documento de venda dentro da coleção Vendas
        const dataVenda = vendaData.dataVenda.toDate();
        const vendaRef = doc(
          db,
          `EMPRESAS/${empresaId}/Vendas/${dataVenda.getFullYear()}/${dataVenda.getMonth() +
            1}/${dataVenda.getDate()}/Vendas/${vendaId}`
        );

        const vendaDocSnap = await getDoc(vendaRef);
        if (vendaDocSnap.exists()) {
          const vendaDocData = vendaDocSnap.data();
          const produtosPorFornecedorAtualizados = vendaDocData.produtosPorFornecedor?.[
            fornecedorSelecionado.id
          ]?.map(produto => ({
            ...produto,
            repasseFornecedor: produto.repasseFornecedor // Usando o valor já existente
          }));

          batch.update(vendaRef, {
            [`produtosPorFornecedor.${fornecedorSelecionado.id}`]: produtosPorFornecedorAtualizados
          });
        }

        // Exclui o documento da coleção Conta, pois o pagamento foi efetuado
        batch.delete(contaRef);

        // Acumular o valor total pago ao fornecedor
        const valorPagoVenda = produtosAtualizados.reduce((total, produto) => {
          return total + (produto.repasseFornecedor || 0);
        }, 0);
        totalPago += valorPagoVenda;

      } else {
        console.error("Venda não encontrada:", vendaId);
      }
    }

    const pagamentoData = {
      idPagamento: pagamentoId,
      dataPagamento: new Date(),
      fornecedorId: fornecedorSelecionado.id,
      metodoPagamento: formaPagamento,
      observacao: observacao,
      urlAnexo: urlAnexo,
      vendasPagas: vendasPagasData,
      statusPagamento: "PAGO",
      totalPago
    };

    // Referência para o documento de pagamento dentro da coleção de pagamentos do fornecedor
    const pagamentoRef = doc(fornecedorPagamentosRef, pagamentoId);

    batch.set(pagamentoRef, pagamentoData);

    await batch.commit();

    // Atualiza o estado para exibir o recibo
    setDadosRecibo(vendasPagasData);
    setEtapaAtual("opcoesRecibo");
    setOpenPagamentoModal(true); // Mantém o modal aberto para opções de recibo

    // Recarrega os dados para refletir as mudanças
    carregarVendasFornecedor(fornecedorSelecionado.id);
  };


  const atualizarStatusPagamento = async (
    fornecedorId,
    vendaId,
    novoStatus
  ) => {
    const vendaRef = doc(
      db,
      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Conta`,
      vendaId
    );
    await updateDoc(vendaRef, { statusPagamento: novoStatus });
    carregarVendasFornecedor(fornecedorId);
  };

  const abrirModalEscolhaFormato = usarFiltrados => {
    setUsarFiltradas(usarFiltrados); // Assumindo que você tem um estado chamado `usarFiltradas`
    setMostrarModalEscolhaFormato(true);
  };

  const handleOpenModal = venda => {
    setSelectedVenda(venda);
    setOpenModal(true); // Certifique-se de que este estado controla a visibilidade do modal de detalhes da venda
  };
  // Função para fechar o modal de detalhes da venda
  const handleCloseDetalhesModal = () => {
    setOpenModal(false); // Atualiza o estado para fechar o modal de detalhes da venda
    setSelectedVenda(null); // Opcional: limpar os detalhes da venda selecionada
  };

  const handleOpenModalPagamento = () => {
    setOpenPagamentoModal(true);
  };
  const handleCloseModal = () => {
    setOpenPagamentoModal(false);
    setMostrarModalRecibo(false);
    setEtapaAtual("pagamento"); // Redefinir para o estado inicial
  };

  const handleCloseReciboModal = () => {
    setMostrarModalRecibo(false);
    setEtapaAtual("pagamento");
    // Outros estados que precisam ser resetados podem ser incluídos aqui
  };
  const resetarEstadoPagamento = () => {
    setOpenPagamentoModal(false); // Fecha o modal
    setEtapaAtual("pagamento"); // Prepara para um novo pagamento
    setDadosRecibo([]); // Limpa os dados do recibo
    setSelecionados([]);
    setObservacao("");
    setFormaPagamento("transferencia"); // Ou outra valor padrão que você deseja
    setArquivoAnexo(null);
    // Outros estados que precisam ser resetados podem ser incluídos aqui
  };

  const iniciarNovoPagamento = () => {
    // Resetar todos os estados relevantes ao processo de pagamento
    setSelecionados([]);
    setObservacao("");
    setFormaPagamento("");
    setArquivoAnexo(null);
    setEtapaAtual("pagamento"); // Garantir que estamos na etapa inicial do pagamento
    setOpenPagamentoModal(true); // Abrir o modal de pagamento
  };
  const fecharModalRecibo = () => {
    setOpenPagamentoModal(false); // Fecha o modal de pagamento/recibo
    setEtapaAtual("pagamento"); // Resetar para o estado inicial de pagamento
    setDadosRecibo({}); // Limpar os dados de recibo para o próximo pagamento
    // Resetar outros estados relevantes, se necessário
    setSelecionados([]);
    setObservacao("");
    setFormaPagamento("");
    setArquivoAnexo(null);
  };

  const proximoPagamento = new Date();
  proximoPagamento.setDate(6); // Ajusta para o dia 6 do próximo mês
  if (proximoPagamento.getDate() <= new Date().getDate()) {
    proximoPagamento.setMonth(proximoPagamento.getMonth() + 1);
  }

  if (loading) {
    return <CircularProgress />;
  }
  // Calculando Valores em Aberto
  const valoresEmAberto = vendasFornecedor
    .filter(venda => venda.statusPagamento === "Aguardando Pagamento")
    .reduce((acc, cur) => acc + parseFloat(cur.totalCaixaFornecedor), 0);

  // Encontrando a Última Venda
  const ultimaVenda =
    vendasFornecedor.sort((a, b) => b.dataVenda - a.dataVenda)[0]
      ?.totalCaixaFornecedor || 0;
  // Função para carregar pagamentos efetuados
  const carregarPagamentosEfetuados = async fornecedorId => {
    setLoading(true);
    const pagamentosRef = collection(
      db,
      `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Pagamentos`
    );
    const q = query(
      pagamentosRef,
      where("dataPagamento", ">=", new Date(dataInicio)),
      where("dataPagamento", "<=", new Date(dataFim))
    );

    const pagamentosSnapshot = await getDocs(q);
    const pagamentos = pagamentosSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    setLoading(false);
  };

  // Função para verificar se a venda ocorreu há mais de 20 dias
  const vendaMaisDe20Dias = dataVendaDate => {
    const hoje = new Date();
    hoje.setHours(0, 0, 0, 0); // Zera a hora para considerar apenas a data

    const diferencaTempo = hoje.getTime() - dataVendaDate.getTime();
    const diferencaDias = diferencaTempo / (1000 * 3600 * 24);

    return diferencaDias > 20;
  };

  const vendasFiltradas = vendasFornecedor.filter(venda => {
    const dataVendaDate = converterTimestampParaDate(venda.dataVenda);
    return !filtrarVendas20Dias || vendaMaisDe20Dias(dataVendaDate);
  });

  return (
    (<Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        paddingBottom: "20px"
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ marginTop: "20px", paddingLeft: { xs: "0", md: "250px" } }}
      >
        <Card
          sx={{
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)"
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
            Contas dos Fornecedores
          </Typography>
          {fornecedores.length > 0 ? (
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <Select
                value={fornecedorIdSelecionado}
                onChange={handleChangeFornecedor}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                }}
              >
                <MenuItem value="" disabled>
                  Selecione um Fornecedor
                </MenuItem>
                {fornecedores.map(fornecedor => (
                  <MenuItem key={fornecedor.id} value={fornecedor.id}>
                    {fornecedor.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>Nenhum fornecedor encontrado.</Typography>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              marginBottom: "20px",
              flexWrap: "wrap"
            }}
          >
            <Card
              variant="outlined"
              sx={{
                flex: 1,
                backgroundColor: "#e3f2fd",
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
                borderRadius: "8px",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)"
                }
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <PaymentIcon sx={{ fontSize: 40, color: "#1976d2" }} />
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontFamily: "Poppins, sans-serif", mt: 1 }}
                >
                  Selecionado para Pagamento
                </Typography>
                <Typography variant="h5">
                  {formatCurrency(totalSelecionado)}
                </Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                flex: 1,
                backgroundColor: "#ffebee",
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
                borderRadius: "8px",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)"
                }
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <AccountBalanceWalletIcon
                  sx={{ fontSize: 40, color: "#d32f2f" }}
                />
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontFamily: "Poppins, sans-serif", mt: 1 }}
                >
                  Em Aberto
                </Typography>
                <Typography variant="h5">
                  {formatCurrency(formatarValor(valoresEmAberto))}
                </Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                flex: 1,
                backgroundColor: "#e8f5e9",
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
                borderRadius: "8px",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)"
                }
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <EventNoteIcon sx={{ fontSize: 40, color: "#388e3c" }} />
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontFamily: "Poppins, sans-serif", mt: 1 }}
                >
                  Última Venda
                </Typography>
                <Typography variant="h5">
                  {formatCurrency(formatarValor(ultimaVenda))}
                </Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              sx={{
                flex: 1,
                backgroundColor: "#fffde7",
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
                borderRadius: "8px",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)"
                }
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <AccessTimeIcon sx={{ fontSize: 40, color: "#fbc02d" }} />
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontFamily: "Poppins, sans-serif", mt: 1 }}
                >
                  Data de Próximo Pagamento
                </Typography>
                <Typography variant="h5">
                  {format(proximoPagamento, "dd/MM/yyyy")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Data Início"
                type="date"
                value={dataInicio}
                onChange={e => setDataInicio(e.target.value)}
                InputLabelProps={{ shrink: true }}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  carregarVendasFornecedor(
                    fornecedorSelecionado.id,
                    dataInicio,
                    dataFim
                  )
                }
                sx={{
                  height: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={filtrarVendas20Dias}
                    onChange={e => setFiltrarVendas20Dias(e.target.checked)}
                    sx={{ "& .MuiSwitch-thumb": { color: "primary.main" } }}
                  />
                }
                label="Filtrar vendas com mais de 20 dias"
                sx={{ fontWeight: "bold", fontFamily: "Poppins, sans-serif" }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={abrirModalEscolhaFormato}
                sx={{
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                Gerar Relatório
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              {selecionados.length > 0 && (
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#4CAF50", color: "#FFF" }}
                  onClick={handleOpenModalPagamento}
                  sx={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Poppins, sans-serif"
                  }}
                >
                  Gerar Pagamento
                </Button>
              )}
            </Grid>
          </Grid>
          {fornecedorSelecionado && (
            <>
              <Typography
                variant="h5"
                style={{
                  marginTop: "20px",
                  marginBottom: "10px",
                  fontWeight: "bold"
                }}
              >
                Produtos vendidos de {fornecedorSelecionado.nome}
              </Typography>

              <Table
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: 3,
                  overflow: "hidden"
                }}
              >
                <TableHead sx={{ backgroundColor: "#1976d2" }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Selecionar
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Documento da Venda
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Data da Venda
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Valor a Receber
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Status do Pagamento
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Detalhes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendasFiltradas.map(venda => (
                    <TableRow key={venda.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selecionados.includes(venda.id)}
                          onChange={() => handleSelect(venda.id)}
                        />
                      </TableCell>
                      <TableCell>{venda.idVenda}</TableCell>
                      <TableCell>
                        {format(new Date(venda.dataVenda), "dd/MM/yyyy")}
                      </TableCell>
                      <TableCell>
                        {formatCurrency(
                          formatarValor(venda.totalCaixaFornecedor)
                        )}
                      </TableCell>

                      <TableCell>{venda.statusPagamento}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpenModal(venda)}
                          sx={{
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            fontFamily: "Poppins, sans-serif"
                          }}
                        >
                          Ver Produtos
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          <Modal open={openPagamentoModal} onClose={resetarEstadoPagamento}>
            <Box sx={EstiloModal}>
              {etapaAtual === "pagamento" && (
                <>
                  <Typography
                    id="modal-pagamento-titulo"
                    variant="h6"
                    component="h2"
                    sx={{
                      mb: 2,
                      color: "primary.main",
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    Pagamento do Fornecedor
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    {selecionados.map(id => (
                      <Typography
                        key={id}
                        sx={{
                          wordBreak: "break-all",
                          fontFamily: "Poppins, sans-serif"
                        }}
                      >
                        Documento: {id}
                      </Typography>
                    ))}
                  </Box>
                  <TextField
                    label="Forma de Pagamento"
                    fullWidth
                    select
                    value={formaPagamento}
                    onChange={e => setFormaPagamento(e.target.value)}
                    SelectProps={{ native: true }}
                    sx={{
                      mb: 2,
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    <option value="transferencia">
                      Transferência Bancária
                    </option>
                    <option value="cheque">Cheque</option>
                    <option value="dinheiro">Dinheiro</option>
                    <option value="pix">Pix</option>
                    <option value="creditoLoja">Crédito em Loja</option>
                  </TextField>

                  <TextField
                    label="Observação"
                    fullWidth
                    multiline
                    rows={4}
                    value={observacao}
                    onChange={e => setObservacao(e.target.value)}
                    sx={{
                      mb: 2,
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <input
                      accept="image/*,.pdf"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={e => setArquivoAnexo(e.target.files[0])}
                    />
                    <label htmlFor="raised-button-file">
                      <IconButton component="span" sx={{ color: "secondary.main" }} size="large">
                        <AttachFileIcon />
                      </IconButton>
                    </label>
                    <Typography
                      sx={{ ml: 1, fontFamily: "Poppins, sans-serif" }}
                    >
                      {arquivoAnexo
                        ? arquivoAnexo.name
                        : "Anexar Recibo Bancário"}
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<PaymentIcon />}
                    onClick={realizarPagamento}
                    sx={{
                      width: "100%",
                      mt: 2,
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    Pagar
                  </Button>
                </>
              )}
              {etapaAtual === "opcoesRecibo" && (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontWeight: "bold",
                      fontFamily: "Poppins, sans-serif"
                    }}
                  >
                    <ReceiptIcon /> Ações do Recibo
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mb: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                    startIcon={<EmailIcon />}
                    onClick={async () => {
                      await handleGerarReciboClick(); // Gera o PDF e envia por email
                      resetarEstadoPagamento(); // Reseta o estado após enviar
                    }}
                  >
                    Enviar Recibo por Email
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      fontFamily: "Poppins, sans-serif"
                    }}
                    startIcon={<PictureAsPdfIcon />}
                    onClick={() => {
                      gerarReciboPDF();
                      resetarEstadoPagamento();
                    }}
                  >
                    Gerar Recibo em PDF
                  </Button>

                  {mostrarRecibo && <Recibo {...dadosRecibo} />}
                </>
              )}
            </Box>
          </Modal>
          <Modal
            open={mostrarModalEscolhaFormato}
            onClose={() => setMostrarModalEscolhaFormato(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: "24px 24px 48px -12px rgba(0,0,0,0.2)",
                p: 4,
                outline: "none"
              }}
            >
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                sx={{ fontWeight: "bold", mb: 2, textAlign: "center" }}
              >
                Formato do Relatório
              </Typography>
              <Box
                sx={{ mt: 2, display: "flex", justifyContent: "space-evenly" }}
              >
                <Button
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  onClick={() => {
                    gerarRelatorioFornecedor(vendasFiltradas, "PDF");
                    setMostrarModalEscolhaFormato(false);
                  }}
                  sx={{
                    borderColor: "primary.main",
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.light",
                      borderColor: "primary.main"
                    },
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Poppins, sans-serif"
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<InsertDriveFileIcon />}
                  onClick={() => {
                    gerarRelatorioFornecedor(vendasFiltradas, "CSV");
                    setMostrarModalEscolhaFormato(false);
                  }}
                  sx={{
                    borderColor: "secondary.main",
                    color: "secondary.main",
                    "&:hover": {
                      backgroundColor: "secondary.light",
                      borderColor: "secondary.main"
                    },
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    fontFamily: "Poppins, sans-serif"
                  }}
                >
                  CSV
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={openModal}
            onClose={handleCloseDetalhesModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={modalStyle}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <AssignmentIcon color="primary" />
                  Detalhes da Venda
                </Typography>
                <IconButton
                  onClick={handleCloseDetalhesModal}
                  size="small"
                  sx={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {selectedVenda ? (
                <>
                  <Typography sx={{ mt: 2 }}>
                    <strong>Documento da Venda:</strong> {selectedVenda.idVenda}
                  </Typography>
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <CalendarTodayIcon color="action" />
                    <strong>Data da Venda:</strong>{" "}
                    {selectedVenda.dataVenda.toLocaleDateString()}
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mb: 2
                    }}
                  >
                    <PaymentIcon color="success" />
                    <strong>Status do Pagamento:</strong>{" "}
                    {selectedVenda.statusPagamento}
                  </Typography>

                  <Table size="small">
                    <TableHead sx={{ backgroundColor: "#1976d2" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Descrição
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Quantidade
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Valor Venda
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Desconto
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Preço Final
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Comissão (%)
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Valor de comissão
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedVenda.produtos.map((produto, index) => (
                        <TableRow key={index}>
                          <TableCell>{produto.descricao}</TableCell>
                          <TableCell align="right">{produto.quantidade}</TableCell>
                          <TableCell align="right">
                            {formatCurrency(formatarValor(produto.valorVenda))}
                          </TableCell>

                          <TableCell align="right">
                            {produto.descontoPercentual > 0
                              ? `${produto.descontoPercentual}%`
                              : produto.descontoMonetario > 0
                              ? `R$ ${produto.descontoMonetario}`
                              : "0"}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(formatarValor(produto.precoFinal))}
                          </TableCell>
                          <TableCell align="right">
                            {produto.porcentagemComissao
                              ? `${produto.porcentagemComissao}%`
                              : "N/A"}
                          </TableCell>
                          <TableCell align="right">
                            {formatCurrency(formatarValor(produto.repasseFornecedor))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                </>
              ) : (
                <Typography>Nenhum detalhe disponível.</Typography>
              )}
            </Box>
          </Modal>
        </Card>
      </Container>
    </Box>)
  );
};

export default FinanceiroFornecedores;

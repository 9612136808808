import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import {
  Container,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Checkbox,
  Button,
  Snackbar
} from "@mui/material";

const PaginaOrcamento = () => {
  const { empresaId, idOrcamento } = useParams(); // Obtendo os parâmetros da URL
  const [orcamento, setOrcamento] = useState(null);
  const [checked, setChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchOrcamento = async () => {
      if (empresaId && idOrcamento) {
        // Verifica se os parâmetros estão definidos
        const orcamentoRef = doc(
          getFirestore(),
          "EMPRESAS",
          empresaId,
          "Lista de Orçamentos",
          idOrcamento
        );
        const orcamentoDoc = await getDoc(orcamentoRef);
        if (orcamentoDoc.exists()) {
          setOrcamento(orcamentoDoc.data());
        } else {
          setSnackbarMessage("Orçamento não encontrado.");
          setOpenSnackbar(true);
        }
      } else {
        setSnackbarMessage("Parâmetros inválidos.");
        setOpenSnackbar(true);
      }
    };

    fetchOrcamento();
  }, [empresaId, idOrcamento]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleAprovarOrcamento = async () => {
    if (!checked) {
      setSnackbarMessage("Você deve concordar com os termos antes de enviar.");
      setOpenSnackbar(true);
      return;
    }

    const orcamentoRef = doc(
      getFirestore(),
      "EMPRESAS",
      empresaId,
      "Lista de Orçamentos",
      idOrcamento
    );

    await updateDoc(orcamentoRef, {
      status: "Aprovado"
    });

    setSnackbarMessage("Orçamento aprovado com sucesso.");
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "20px" }}>
      <Card
        style={{
          borderRadius: "15px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)"
        }}
      >
        <CardContent>
          {orcamento ? (
            <>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  marginBottom: "20px",
                  fontWeight: "bold",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                Orçamento {orcamento.numero}
              </Typography>
              <Typography
                variant="h6"
                component="h3"
                style={{
                  marginBottom: "20px",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                Fornecedor: {orcamento.fornecedor}
              </Typography>
              <List>
                {orcamento.pecas.map((peca, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={peca.descricao}
                        secondary={`Quantidade: ${peca.quantidade} | Venda: R$${peca.valorVenda} | Compra: R$${peca.valorCompra} | Marca: ${peca.marca} | Tamanho: ${peca.tamanho} | Categoria: ${peca.categoria} | Entrada: ${peca.dataEntrada}`}
                      />
                    </ListItem>
                    {index < orcamento.pecas.length - 1 && (
                      <Divider component="li" />
                    )}
                  </React.Fragment>
                ))}
              </List>
              <div style={{ marginTop: "20px" }}>
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "Concordo com os termos" }}
                />
                <Typography variant="body1" component="span">
                  Concordo com os termos.
                </Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAprovarOrcamento}
                disabled={!checked}
                style={{ marginTop: "20px" }}
              >
                Enviar
              </Button>
            </>
          ) : (
            <Typography
              variant="h6"
              component="h3"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Carregando orçamento...
            </Typography>
          )}
        </CardContent>
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default PaginaOrcamento;

import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { useParams } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Paper,
  Box,
  Grid
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';

const ErrorModal = ({ isOpen, onClose, message }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>Erro</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Fechar</Button>
    </DialogActions>
  </Dialog>
);

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { empresaId } = useParams();

  useEffect(() => {
    if (empresaId) {
      // Armazena o empresaId no localStorage
      localStorage.setItem('lastEmpresaId', empresaId);
    }
  }, [empresaId]);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      console.log("Tentando fazer login...");
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const uid = userCredential.user.uid;
      console.log("Login bem-sucedido:", uid);

      const db = getFirestore();
      const userDocRef = doc(db, "USUARIOS", uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const empresaId = userDoc.data().empresaId;
        console.log("Empresa ID:", empresaId);

        const storage = getStorage();
        const logoRef = ref(storage, `${empresaId}/logo/logo.svg`);
        getDownloadURL(logoRef)
          .then(url => {
            console.log("URL da logo:", url);
            setLogoUrl(url);
          })
          .catch(error => {
            console.error("Erro ao carregar a logo da empresa:", error);
          });

        navigate(`/dashboard`);
      } else {
        console.log("Usuário não encontrado ou não associado a uma empresa.");
        setError("Usuário não encontrado ou não associado a uma empresa.");
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Erro ao fazer login:", error.message);
      setError("E-mail ou senha incorretos.");
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (empresaId) {
        setLoading(true);
        try {
          const storage = getStorage();
          const logoRef = ref(storage, `${empresaId}/logo/logo.svg`);
          const url = await getDownloadURL(logoRef);
          console.log("URL da logo encontrada:", url);
          setLogoUrl(url);
        } catch (error) {
          console.error("Erro ao buscar a logo:", error);
          setError("Erro ao carregar a logo da empresa.");
          setIsModalOpen(true);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchLogoUrl();
  }, [empresaId]);

  const TIMEOUT = 30 * 60 * 1000; // 30 minutos de inatividade
  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        auth.signOut();
        navigate("/login");
      }, TIMEOUT);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);

    resetTimer();

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
    };
  }, [navigate]);

  const handleResetPassword = async () => {
    if (!email) {
      alert("Por favor, insira seu e-mail para redefinição de senha.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("E-mail de redefinição de senha enviado. Verifique sua caixa de entrada.");
    } catch (error) {
      console.error("Erro ao enviar e-mail de redefinição de senha:", error);
      setError("Falha ao enviar e-mail de redefinição de senha.");
      setIsModalOpen(true);
    }
  };

  return (
    <Container component="main">
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Grid
          container
          style={{ minHeight: "100vh" }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
            <Paper
              elevation={3}
              sx={{
                padding: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              {logoUrl && (
                <Box
                  component="img"
                  sx={{ height: 100, mb: 2 }}
                  src={logoUrl}
                  alt="Logo da Empresa"
                />
              )}
              <Typography component="h1" variant="h5">
                Bem-vindo(a)
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Entrar
                </Button>
                <Button
                  onClick={handleResetPassword}
                  fullWidth
                  variant="text"
                  sx={{ mt: 1 }}
                >
                  Esqueceu a senha?
                </Button>
              </Box>
            </Paper>

            <ErrorModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              message={error}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default LoginPage;

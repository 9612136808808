import React, { useState, useEffect } from 'react';
import { db } from './firebase'; // Certifique-se de importar seu arquivo de configuração do Firebase
import { collection, getDocs } from 'firebase/firestore';
import { Bar, Pie } from 'react-chartjs-2';
import { Container, Paper, useMediaQuery, Typography, Grid } from '@mui/material';
import { Chart as ChartJS } from 'chart.js/auto';
import { ThemeProvider, StyledEngineProvider, createTheme, useTheme } from '@mui/material/styles';

const Relatorios = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [dadosVendas, setDadosVendas] = useState({
    labels: [],
    datasets: [{ data: [] }],
  });
  const [dadosFinanceiros, setDadosFinanceiros] = useState({
    labels: [],
    datasets: [{ data: [] }],
  });
  const [creditoFornecedores, setCreditoFornecedores] = useState({
    labels: [],
    datasets: [{ data: [] }],
  });

  useEffect(() => {
    fetchDadosVendas();
    fetchDadosFinanceiros();
    fetchCreditoFornecedores();
  }, []);

  const fetchDadosVendas = async () => {
    // Buscar e processar dados de vendas
    const dados = {
      labels: ['Dia 1', 'Dia 2', 'Dia 3', 'Dia 4', 'Dia 5'], // Substitua pelos últimos 30 dias
      datasets: [{
        label: 'Vendas por Dia',
        data: [12, 19, 3, 5, 2, 3], // Substitua pelos dados reais
        backgroundColor: 'rgba(54, 162, 235, 0.5)'
      }]
    };
    setDadosVendas(dados);
  };

  const fetchDadosFinanceiros = async () => {
    // Buscar e processar dados financeiros
    const dados = {
      labels: ['Receitas', 'Despesas'],
      datasets: [{
        data: [300, 200], // Substitua pelos dados reais
        backgroundColor: ['#36A2EB', '#FF6384']
      }]
    };
    setDadosFinanceiros(dados);
  };

  const fetchCreditoFornecedores = async () => {
    // Buscar e processar crédito dos fornecedores
    const dados = {
      labels: ['Fornecedor 1', 'Fornecedor 2', 'Fornecedor 3'],
      datasets: [{
        data: [120, 150, 100], // Substitua pelos dados reais
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
      }]
    };
    setCreditoFornecedores(dados);
  };

  return (
    <Container
    maxWidth="xl" // Define um maxWidth maior para permitir mais espaço em telas grandes
    style={{
      marginTop: "20px",
      paddingLeft: isMdUp ? "250px" : "0", // Ajusta o paddingLeft baseado no tamanho da tela
    }}
  >
      <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper style={{ padding: '20px', marginBottom: '20px' }}>
                        <Typography variant="h6">Relatório de Vendas (Últimos 30 dias)</Typography>
        <Bar data={dadosVendas} />
      </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
                    <Paper style={{ padding: '20px', height: '100%' }}>
                        <Typography variant="h6">Relatório Financeiro</Typography>
                        <Pie data={dadosFinanceiros} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper style={{ padding: '20px', height: '100%' }}>
                        <Typography variant="h6">Crédito por Fornecedor</Typography>
                        <Pie data={creditoFornecedores} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Relatorios;

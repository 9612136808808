// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Importar getStorage

// Your web app's Firebase configuration

 const firebaseConfig = {
  apiKey: "AIzaSyDjmAWIExophyftPkoTCbCquapo5pnilNk",
  authDomain: "dona-cora.firebaseapp.com",
  databaseURL: "https://dona-cora-default-rtdb.firebaseio.com",
  projectId: "dona-cora",
  storageBucket: "dona-cora.appspot.com",
  messagingSenderId: "937535499633",
  appId: "1:937535499633:web:7079e213bfc08f6e579ad0",
  measurementId: "G-Q27NXG6F0B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app); // Inicializar o storage

export { db, doc, getDoc, auth, storage }; // Exportar o storage também
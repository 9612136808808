import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Container,
  TextField,
  Button,
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Divider,
  Box,
  Checkbox,
  IconButton,
  FormControlLabel,
  InputAdornment,
  Modal,
  CardMedia,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { db } from "./firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";
import GerenciarEstoque from './Gerenciarprodutos';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  getFirestore,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { ThemeProvider, StyledEngineProvider, useTheme } from "@mui/material/styles";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { QrReader } from "react-qr-reader";

const PDVVendas = () => {
  const theme = useTheme();
  const { userDetails } = useContext(AuthContext);
  const empresaId = userDetails.empresaId;
  const [codigoBarras, setCodigoBarras] = useState("");
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [carrinho, setCarrinho] = useState([]);
  const [desconto, setDesconto] = useState(0);
  const [descontoPercentual, setDescontoPercentual] = useState("");
  const [descontoMonetario, setDescontoMonetario] = useState("");
  const [openComissaoModal, setOpenComissaoModal] = useState(false);
  const [comissaoManual, setComissaoManual] = useState(50);
  const [openEstoqueModal, setOpenEstoqueModal] = useState(false);

  const handleOpenEstoqueModal = () => {
    setOpenEstoqueModal(true);
  };

  const handleCloseEstoqueModal = () => {
    setOpenEstoqueModal(false);
  };

  const [fornecedorSemComissao, setFornecedorSemComissao] = useState(null); 
  const timestamp = Timestamp.fromDate(new Date());
  const [tipoPagamento, setTipoPagamento] = useState({
    dinheiro: false,
    pix: false,
    cartao: {
      ativo: false,
      debito: false,
      credito: false,
      parcelas: 1,
    },
    divisao: false,
    dinheiroDivisao: "",
    cartaoDivisao: "",
  });
  const [loadingVenda, setLoadingVenda] = useState(false);
  const [informacoesEmpresa, setInformacoesEmpresa] = useState({});
  const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(1);
  const [showScanner, setShowScanner] = useState(false);
  const [vendaTipo, setVendaTipo] = useState("cliente");
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const abrirScanner = () => setShowScanner(true);
  const handleClose = () => setShowScanner(false);

  const handleScan = (result) => {
    if (result?.text) {
      setCodigoBarras(result.text);
      setShowScanner(false);
    }
  };

  const handleError = (error) => {
    console.error(error);
    setShowScanner(false);
  };

  const buscarProdutoPorCodigoPeca = async () => {
    if (!codigoBarras) {
      console.log("Digite um código da peça válido");
      return;
    }
    try {
      const estoqueAtivoRef = collection(
        db,
        "EMPRESAS",
        empresaId,
        "Cadastro de Peças",
        "dados",
        "Estoque Ativo"
      );
      const q = query(estoqueAtivoRef, where("codigoPeca", "==", codigoBarras));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const produtoData = querySnapshot.docs[0].data();
        setProdutoSelecionado(produtoData);
        adicionarAoCarrinho(produtoData); // Adicionar automaticamente ao carrinho
      } else {
        alert("Produto não cadastrado");
        setProdutoSelecionado(null);
      }
    } catch (error) {
      console.error("Erro ao buscar produto:", error);
      setProdutoSelecionado(null);
    }
  };

  useEffect(() => {
    if (codigoBarras.length === 8) {
      buscarProdutoPorCodigoPeca();
    }
  }, [codigoBarras]);

  const calcularPrecoComDesconto = (valorVenda, descontoPercentual, descontoMonetario) => {
    let precoComDesconto = valorVenda - (valorVenda * descontoPercentual / 100) - descontoMonetario;
    return parseFloat(precoComDesconto.toFixed(2));
  };

  const adicionarAoCarrinho = (produto) => {
    if (produto && quantidadeSelecionada > 0) {
      const precoComDesconto = calcularPrecoComDesconto(produto.valorVenda, descontoPercentual, descontoMonetario);

      const produtoComDesconto = {
        ...produto,
        quantidade: quantidadeSelecionada,
        descontoPercentual: parseFloat(descontoPercentual) || 0,
        descontoMonetario: parseFloat(descontoMonetario) || 0,
        precoFinal: precoComDesconto,
      };

      setCarrinho((prevCarrinho) => [...prevCarrinho, produtoComDesconto]);
      setDescontoPercentual("");
      setDescontoMonetario("");
      setCodigoBarras("");
      setQuantidadeSelecionada(1);
    }
  };

  const handleQuantidadeChange = (index, novaQuantidade) => {
    setCarrinho((prevCarrinho) =>
      prevCarrinho.map((item, idx) => {
        if (idx === index) {
          const precoComDesconto = calcularPrecoComDesconto(item.valorVenda, item.descontoPercentual, item.descontoMonetario);

          return {
            ...item,
            quantidade: parseInt(novaQuantidade, 10) || 1,
            precoFinal: precoComDesconto,
          };
        }
        return item;
      })
    );
  };

  const handleDescontoChange = (index, novoDesconto, tipoDesconto) => {
    setCarrinho((prevCarrinho) =>
      prevCarrinho.map((item, idx) => {
        if (idx === index) {
          const descontoPercentualNum = tipoDesconto === 'percentual' ? parseFloat(novoDesconto) || 0 : item.descontoPercentual;
          const descontoMonetarioNum = tipoDesconto === 'monetario' ? parseFloat(novoDesconto) || 0 : item.descontoMonetario;

          const precoComDesconto = calcularPrecoComDesconto(item.valorVenda, descontoPercentualNum, descontoMonetarioNum);

          return {
            ...item,
            descontoPercentual: descontoPercentualNum,
            descontoMonetario: descontoMonetarioNum,
            precoFinal: precoComDesconto,
          };
        }
        return item;
      })
    );
  };

  const setModoDesconto = (index, modo) => {
    setCarrinho((prevCarrinho) =>
      prevCarrinho.map((item, idx) => {
        if (idx === index) {
          if (modo === "percentual" && item.modoDesconto !== "percentual") {
            item.descontoMonetario = 0;
          } else if (modo === "monetario" && item.modoDesconto !== "monetario") {
            item.descontoPercentual = 0;
          }
          return {
            ...item,
            modoDesconto: modo,
          };
        }
        return item;
      })
    );
  };

  const excluirDoCarrinho = (index) => {
    const novoCarrinho = carrinho.filter((_, idx) => idx !== index);
    setCarrinho(novoCarrinho);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const calcularSubtotal = () => {
    return carrinho.reduce((total, item) => {
      return total + item.precoFinal * item.quantidade;
    }, 0);
  };

  const calcularTotalDescontos = () => {
    return carrinho.reduce((total, item) => {
      const precoOriginal = item.valorVenda;
      const descontoAplicado = precoOriginal - item.precoFinal;
      return total + descontoAplicado * item.quantidade;
    }, 0);
  };

  const calcularQuantidadeTotal = () => {
    return carrinho.reduce((total, item) => total + item.quantidade, 0);
  };

  const realizarVenda = async () => {
    try {
      setLoadingVenda(true);

      if (carrinho.length === 0) {
        throw new Error("O carrinho está vazio");
      }

      if (
        !tipoPagamento.dinheiro &&
        !tipoPagamento.pix &&
        !tipoPagamento.cartao.ativo &&
        !tipoPagamento.divisao
      ) {
        throw new Error("Selecione um método de pagamento");
      }

      const db = getFirestore();
      const produtosPorFornecedor = {};
      let produtosParaExcluir = [];
      let totalCaixaEmpresa = 0;

      for (const produto of carrinho) {
        const produtoRef = doc(
          db,
          `EMPRESAS/${empresaId}/Cadastro de Peças/dados/Estoque Ativo/${produto.codigoPeca}`
        );
        const docSnap = await getDoc(produtoRef);

        if (
          !docSnap.exists() ||
          docSnap.data().quantidade < produto.quantidade
        ) {
          throw new Error(
            `Estoque insuficiente para o produto: ${produto.descricao}`
          );
        }

        const quantidadeAtualizada =
          docSnap.data().quantidade - produto.quantidade;
        await updateDoc(produtoRef, { quantidade: quantidadeAtualizada });

        if (quantidadeAtualizada === 0) {
          produtosParaExcluir.push({
            codigoPeca: produto.codigoPeca,
            imageUrl: produto.imageUrl,
          });
        }

        const fornecedorId = produto.fornecedor || "semFornecedor";

        let porcentagemComissaoFornecedor = 50; // valor padrão apenas como fallback, não será utilizado diretamente

        if (fornecedorId !== "semFornecedor") {
          const fornecedorRef = doc(
            db,
            `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}`
          );
          const fornecedorSnap = await getDoc(fornecedorRef);
          if (fornecedorSnap.exists()) {
            const porcentagem = fornecedorSnap.data().porcentagem;
            if (porcentagem !== undefined) {
              porcentagemComissaoFornecedor = porcentagem;
            } else {
              setFornecedorSemComissao(fornecedorId);
              setOpenComissaoModal(true);
              return; // Pausa o processo até que a comissão seja inserida manualmente
            }
          }
        }

        // Continua o processamento da venda com a comissão definida
        const repasseFornecedor = (produto.precoFinal * produto.quantidade) * (porcentagemComissaoFornecedor / 100);
        const valorRecebidoPelaVenda = (produto.precoFinal * produto.quantidade) - repasseFornecedor;
        totalCaixaEmpresa += valorRecebidoPelaVenda;

        if (!produtosPorFornecedor[fornecedorId]) {
          produtosPorFornecedor[fornecedorId] = [];
        }
        produtosPorFornecedor[fornecedorId].push({
          ...produto,
          porcentagemComissao: porcentagemComissaoFornecedor,  // Adiciona a porcentagem de comissão ao objeto produto
          valorRecebidoPelaVenda,
          repasseFornecedor,
        });
      }

      const hoje = new Date();
      const idVenda = `${hoje.getTime()}`;

      for (const [fornecedorId, produtos] of Object.entries(produtosPorFornecedor)) {
        let totalCaixaFornecedor = produtos.reduce((acc, produto) => acc + produto.repasseFornecedor, 0);

        if (fornecedorId !== "semFornecedor") {
          const vendaFornecedorRef = doc(
            db,
            `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorId}/Conta`,
            idVenda
          );
          await setDoc(vendaFornecedorRef, {
            dataVenda: hoje,
            produtos,
            totalCaixaFornecedor,
            statusPagamento: "Aguardando Pagamento",
            metodoPagamento: tipoPagamento,
            idVenda,
          });
        }
      }

      const vendaRef = doc(
        db,
        `EMPRESAS/${empresaId}/Vendas/${hoje.getFullYear()}/${hoje.getMonth() + 1}/${hoje.getDate()}/Vendas`,
        idVenda
      );
      await setDoc(vendaRef, {
        idVenda,
        produtosPorFornecedor,
        tipoPagamento,
        dataVenda: hoje,
        totalCaixaEmpresa,
        statusPagamento: "Aguardando Pagamento",
        metodoPagamento: tipoPagamento,
      });

      const storage = getStorage();
      for (const { codigoPeca, imageUrl } of produtosParaExcluir) {
        const produtoRef = doc(
          db,
          `EMPRESAS/${empresaId}/Cadastro de Peças/dados/Estoque Ativo/${codigoPeca}`
        );
        await deleteDoc(produtoRef);

        if (imageUrl) {
          const imagePath = new URL(imageUrl).pathname
            .split("/o/")[1]
            .split("?")[0];
          const imageRef = ref(storage, decodeURIComponent(imagePath));
          await deleteObject(imageRef);
        }
      }

      imprimirCupomFiscal(idVenda);

      setCarrinho([]);
      setTipoPagamento({
        dinheiro: false,
        pix: false,
        cartao: { ativo: false, debito: false, credito: false, parcelas: 1 },
        divisao: false,
        dinheiroDivisao: 0,
        cartaoDivisao: 0,
      });
      alert("Venda realizada com sucesso!");
    } catch (error) {
      console.error("Erro na venda:", error);
      alert(`Erro na venda: ${error.message}`);
    } finally {
      setLoadingVenda(false);
    }
  };



  // Função para lidar com a confirmação da comissão manual e continuar a venda
  const handleConfirmarComissao = async () => {
    try {
      if (fornecedorSemComissao) {
        const fornecedorRef = doc(
          db,
          `EMPRESAS/${empresaId}/Cadastro de fornecedores/${fornecedorSemComissao}`
        );
        // Atualiza a comissão manualmente no Firestore
        await updateDoc(fornecedorRef, {
          porcentagem: comissaoManual,
        });
        setOpenComissaoModal(false);
        realizarVenda(); // Chama a função de venda novamente para continuar
      }
    } catch (error) {
      console.error("Erro ao salvar comissão manual:", error);
    }
  };
  const buscarInformacoesEmpresa = async () => {
    try {
      const empresaRef = doc(db, "EMPRESAS", empresaId);
      const docSnap = await getDoc(empresaRef);

      if (docSnap.exists()) {
        setInformacoesEmpresa(docSnap.data());
      } else {
        console.log("Informações da empresa não encontradas!");
      }
    } catch (error) {
      console.error("Erro ao buscar informações da empresa:", error);
    }
  };

  const imprimirCupomFiscal = (idVenda) => {
    let conteudoCupom = `CUPOM FISCAL - ${informacoesEmpresa.nome ||
      empresaId}\n`;
    conteudoCupom += `CNPJ: ${informacoesEmpresa.CNPJ ||
      "00.000.000/0000-00"}\n`;
    conteudoCupom += `Endereço: ${informacoesEmpresa.endereco ||
      "Endereço não disponível"}\n`;
    conteudoCupom += `Telefone: ${informacoesEmpresa.telefone ||
      "Telefone não disponível"}\n\n`;
    conteudoCupom += `Código da Venda: ${idVenda}\n`;
    conteudoCupom += "Data: " + new Date().toLocaleString() + "\n";
    conteudoCupom += "-----------------------------------------\n";

    conteudoCupom +=
      "Qtde  Código   Descrição       Valor Unit   Valor Total\n";
    conteudoCupom += "----------------------------------------\n";

    carrinho.forEach((item) => {
      const valorUnitario = formatCurrency(
        parseFloat(item.valorVenda)
      ).padStart(10);
      const valorTotal = formatCurrency(
        parseFloat(item.precoFinal) * item.quantidade
      ).padStart(10);

      conteudoCupom += `${(item.quantidade || 1)
        .toString()
        .padEnd(4)}  ${item.codigoPeca.padEnd(8)}  ${item.descricao
        .substring(0, 15)
        .padEnd(17)}  ${valorUnitario}  ${valorTotal}\n`;
    });

    conteudoCupom += "-----------------------------------------\n";
    conteudoCupom += `QTDE TOTAL DE ITENS: ${calcularQuantidadeTotal()
      .toString()
      .padStart(2)}\n`;
    conteudoCupom += `VALOR TOTAL: ${formatCurrency(
      calcularSubtotal()
    ).padStart(18)}\n`;
    conteudoCupom += `VALOR TOTAL EM DESCONTOS: ${formatCurrency(
      calcularTotalDescontos()
    ).padStart(12)}\n`;
    conteudoCupom += "-----------------------------------------\n";
    conteudoCupom += "FORMA DE PAGAMENTO:\n";
    if (tipoPagamento.dinheiro) conteudoCupom += "- Dinheiro\n";
    if (tipoPagamento.pix) conteudoCupom += "- PIX\n";
    if (tipoPagamento.cartao.ativo) {
      conteudoCupom += tipoPagamento.cartao.debito
        ? "- Cartão Débito\n"
        : tipoPagamento.cartao.credito
        ? `- Cartão Crédito - Parcelas: ${tipoPagamento.cartao.parcelas}\n`
        : "- Cartão\n";
    }
    if (tipoPagamento.divisao) {
      conteudoCupom += `- Dinheiro: ${formatCurrency(
        tipoPagamento.dinheiroDivisao
      )}\n`;
      conteudoCupom += `- Cartão: ${formatCurrency(
        tipoPagamento.cartaoDivisao
      )}\n`;
    }

    console.log(conteudoCupom);
  };

  const handleTipoPagamentoChange = (event) => {
    const { name, checked } = event.target;
    if (name === "dinheiro" || name === "pix") {
      setTipoPagamento({
        ...tipoPagamento,
        [name]: checked,
      });
    } else if (name === "divisao") {
      setTipoPagamento({
        ...tipoPagamento,
        divisao: checked,
      });
    } else {
      setTipoPagamento({
        ...tipoPagamento,
        cartao: {
          ...tipoPagamento.cartao,
          ativo: checked,
          debito: false,
          credito: false,
        },
      });
    }
  };

  const handleTipoCartaoChange = (name, checked) => {
    setTipoPagamento({
      ...tipoPagamento,
      cartao: {
        ...tipoPagamento.cartao,
        [name]: checked,
        ativo: name === "ativo" ? checked : tipoPagamento.cartao.ativo,
        debito: name === "debito" ? checked : tipoPagamento.cartao.debito,
        credito: name === "credito" ? checked : tipoPagamento.cartao.credito,
        parcelas:
          name === "credito" && !checked ? 1 : tipoPagamento.cartao.parcelas,
      },
    });
  };

  const handleParcelasChange = (event) => {
    setTipoPagamento({
      ...tipoPagamento,
      cartao: {
        ...tipoPagamento.cartao,
        parcelas: parseInt(event.target.value, 10),
      },
    });
  };

  const handleDivisaoChange = (name, value) => {
    setTipoPagamento({
      ...tipoPagamento,
      [name]: parseFloat(value) || 0,
    });
  };

  const handleVendaTipoChange = (event) => {
    setVendaTipo(event.target.value);
  };

  return (
    (<Box
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        paddingBottom: "20px",
      }}
    >
      <Container
        maxWidth="xl"
        style={{
          marginTop: "20px",
          paddingLeft: isMdUp ? "220px" : "16px",
          paddingRight: "16px",
          maxWidth: isMdUp ? "calc(110% - 240px)" : "100%", // Ajustando o espaço restante da tela ao lado do sidebar
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                padding: "16px",
                boxShadow: 3,
                borderRadius: "8px",
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                label="Código de Barras"
                fullWidth
                value={codigoBarras}
                onChange={(e) => setCodigoBarras(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={abrirScanner} size="large">
                        <CameraAltIcon />
                      </IconButton>
                      <IconButton onClick={handleOpenEstoqueModal} size="large">
                        <SearchIcon /> {/* Ícone para consultar o estoque */}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </Grid>
          <Modal
            open={openEstoqueModal}
            onClose={handleCloseEstoqueModal}
            aria-labelledby="estoque-modal-title"
            aria-describedby="estoque-modal-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: isMdUp ? "70%" : "90%",
                maxHeight: "90vh",
                overflowY: "auto",
                backgroundColor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
              }}
            >
              <IconButton
                onClick={handleCloseEstoqueModal}
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "grey.600",
                }}
                size="large">
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  mb: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  pb: 2,
                }}
              >
                Gerenciar Estoque
              </Typography>
              <GerenciarEstoque />
            </Box>
          </Modal>



          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                padding: "16px",
                marginBottom: "16px",
                boxShadow: 3,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                Detalhes dos Produtos
              </Typography>
              {carrinho.map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    display: "flex",
                    marginBottom: "8px",
                    alignItems: "center",
                    border: "1px solid #e0e0e0",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={item.imageUrl}
                    alt={item.descricao}
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: "4px",
                      margin: "8px",
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {item.descricao.length > 10
                            ? item.descricao.substring(0, 10) + "..."
                            : item.descricao}
                        </Typography>
                        <Typography variant="body2">
                          Fornecedor: {item.fornecedor}
                        </Typography>
                        <Typography variant="body2">
                          Tamanho: {item.tamanho}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "#ff5722" }}
                        >
                          Preço: {formatCurrency(item.valorVenda)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            marginRight={2}
                          >
                            <TextField
                              label="Qtde"
                              type="number"
                              value={item.quantidade}
                              onChange={(e) =>
                                handleQuantidadeChange(index, e.target.value)
                              }
                              sx={{ minWidth: "60px" }}
                            />
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="auto"
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              marginRight={2}
                            >
                              <IconButton
                                color={
                                  item.modoDesconto === "percentual"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => setModoDesconto(index, "percentual")}
                                size="large">
                                <PercentIcon
                                  sx={{
                                    color:
                                      item.modoDesconto === "percentual"
                                        ? "blue"
                                        : "grey",
                                  }}
                                />
                              </IconButton>
                              <Typography variant="body2">%</Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              marginRight={2}
                            >
                              <IconButton
                                color={
                                  item.modoDesconto === "monetario"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => setModoDesconto(index, "monetario")}
                                size="large">
                                <AttachMoneyIcon
                                  sx={{
                                    color:
                                      item.modoDesconto === "monetario"
                                        ? "green"
                                        : "grey",
                                  }}
                                />
                              </IconButton>
                              <Typography variant="body2">R$</Typography>
                            </Box>
                            {item.modoDesconto === "percentual" && (
                              <TextField
                                label="Desconto (%)"
                                type="number"
                                value={item.descontoPercentual}
                                onChange={(e) =>
                                  handleDescontoChange(
                                    index,
                                    e.target.value,
                                    "percentual"
                                  )
                                }
                                inputProps={{ min: "0" }}
                                sx={{ minWidth: "80px", marginLeft: "5px" }}
                              />
                            )}
                            {item.modoDesconto === "monetario" && (
                              <TextField
                                label="Desconto (R$)"
                                type="number"
                                value={item.descontoMonetario}
                                onChange={(e) =>
                                  handleDescontoChange(
                                    index,
                                    e.target.value,
                                    "monetario"
                                  )
                                }
                                inputProps={{ min: "0" }}
                                sx={{ minWidth: "80px", marginLeft: "5px" }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ textAlign: "right" }}
                      >
                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                          <Typography
                            variant="body2"
                            sx={{ marginTop: "10px", fontWeight: "bold", color: "#388e3c" }}
                          >
                            Total:{" "}
                            {formatCurrency(
                              parseFloat(item.precoFinal) * item.quantidade
                            )}
                          </Typography>
                          <Button onClick={() => excluirDoCarrinho(index)}>
                            <DeleteIcon sx={{ color: "#d32f2f" }} />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
            </Paper>
          </Grid>

          {loadingVenda && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                position: "fixed",
                width: "100%",
                top: 0,
                left: 0,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Grid item xs={12} md={4}>
            <Paper
              sx={{
                padding: "16px",
                overflowX: "auto",
                boxShadow: 3,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#1976d2" }}
              >
                <ReceiptIcon /> Carrinho de Compras
              </Typography>

              <Grid
                container
                sx={{
                  fontWeight: "bold",
                  marginBottom: "15px",
                  textAlign: "left",
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: "10px",
                }}
              >
                <Grid item xs={2}>
                  Qtde
                </Grid>
                <Grid item xs={3}>
                  Código
                </Grid>
                <Grid item xs={4}>
                  Descrição
                </Grid>
                <Grid item xs={3}>
                  Total
                </Grid>
              </Grid>

              {carrinho.map((item, index) => (
                <Grid
                  container
                  key={index}
                  sx={{
                    marginBottom: "10px",
                    textAlign: "left",
                    borderBottom: "1px solid #f0f0f0",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={2}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {item.quantidade || 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {item.codigoPeca}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {item.descricao.length > 10
                        ? item.descricao.substring(0, 10) + "..."
                        : item.descricao}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" sx={{ fontSize: "0.875rem" }}>
                      {formatCurrency(
                        parseFloat(item.precoFinal) * item.quantidade
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              ))}

              <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  QTDE TOTAL DE ITENS: {calcularQuantidadeTotal()}
                </Typography>
                <Typography variant="h6" sx={{ color: "#388e3c" }}>
                  VALOR TOTAL: {formatCurrency(calcularSubtotal())}
                </Typography>
                <Typography variant="body2" sx={{ color: "#d32f2f" }}>
                  VALOR TOTAL EM DESCONTOS:{" "}
                  {formatCurrency(calcularTotalDescontos())}
                </Typography>
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.dinheiro}
                        onChange={handleTipoPagamentoChange}
                        name="dinheiro"
                      />
                    }
                    label="Dinheiro"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.pix}
                        onChange={handleTipoPagamentoChange}
                        name="pix"
                      />
                    }
                    label="PIX"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.cartao.ativo}
                        onChange={(e) => handleTipoPagamentoChange(e)}
                        name="cartao"
                      />
                    }
                    label="Cartão"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tipoPagamento.divisao}
                        onChange={(e) => handleTipoPagamentoChange(e)}
                        name="divisao"
                      />
                    }
                    label="Divisão de Pagamento"
                  />
                  {tipoPagamento.divisao && (
                    <>
                      <TextField
                        label="Dinheiro"
                        type="number"
                        value={tipoPagamento.dinheiroDivisao}
                        onChange={(e) =>
                          handleDivisaoChange("dinheiroDivisao", e.target.value)
                        }
                        sx={{ marginTop: "10px", minWidth: "100px" }}
                      />
                      <TextField
                        label="Cartão"
                        type="number"
                        value={tipoPagamento.cartaoDivisao}
                        onChange={(e) =>
                          handleDivisaoChange("cartaoDivisao", e.target.value)
                        }
                        sx={{ marginTop: "10px", minWidth: "100px" }}
                      />
                    </>
                  )}
                  {tipoPagamento.cartao.ativo && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tipoPagamento.cartao.debito}
                            onChange={(e) =>
                              handleTipoCartaoChange("debito", e.target.checked)
                            }
                            name="debito"
                          />
                        }
                        label="Débito"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tipoPagamento.cartao.credito}
                            onChange={(e) =>
                              handleTipoCartaoChange("credito", e.target.checked)
                            }
                            name="credito"
                          />
                        }
                        label="Crédito"
                      />
                      {tipoPagamento.cartao.credito && (
                        <TextField
                          label="Parcelas"
                          type="number"
                          value={tipoPagamento.cartao.parcelas}
                          onChange={(e) => handleParcelasChange(e)}
                          inputProps={{ min: 1 }}
                          sx={{ marginTop: "10px", minWidth: "100px" }}
                        />
                      )}
                    </>
                  )}
                </Typography>
              </Box>

              <FormControl component="fieldset" sx={{ marginTop: "20px" }}>
                <FormLabel component="legend">Tipo de Venda</FormLabel>
                <RadioGroup
                  aria-label="vendaTipo"
                  name="vendaTipo"
                  value={vendaTipo}
                  onChange={handleVendaTipoChange}
                >
                  <FormControlLabel
                    value="cliente"
                    control={<Radio />}
                    label="Venda Cliente"
                  />
                  <FormControlLabel
                    value="fornecedor"
                    control={<Radio />}
                    label="Venda Fornecedor"
                  />
                </RadioGroup>
              </FormControl>

              <Button
                variant="contained"
                color="success"
                startIcon={<ShoppingCartIcon />}
                sx={{ marginTop: "20px" }}
                fullWidth
                onClick={realizarVenda}
              >
                Pagar
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>)
  );
};

export default PDVVendas;
